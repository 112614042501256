import { Box, Button, Typography, Stack } from '@mui/joy'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import GoogleAccountsSelect from '../../../../../../../../../../../components/integrations/Google/GoogleAccountsSelect'
import { GoogleDriveIcon } from 'assets/icons/KnowledgeBaseIcons'

interface GoogleDriveProps {
    onBack: () => void
}

export default function GoogleDrive({ onBack }: GoogleDriveProps) {
    return (
        <Box display="flex" flexDirection="column" gap={2}>
            <Box display="flex" alignItems="center" gap={1}>
                <Button variant="plain" color="neutral" startDecorator={<ArrowBackIcon />} onClick={onBack} sx={{ alignSelf: 'flex-start' }}>
                    Back
                </Button>
            </Box>

            <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
                <Stack direction="row" alignItems="center" gap={1}>
                    <GoogleDriveIcon sx={{ fontSize: 64 }} />
                    <Stack>
                        <Typography level="h3">Google Drive</Typography>
                        <Typography level="body-sm" color="neutral">
                            Add Google Docs and Spreadsheets to your knowledge base
                        </Typography>
                    </Stack>
                </Stack>
                <GoogleAccountsSelect />
            </Box>
        </Box>
    )
}
