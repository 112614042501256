import { Modal, Sheet, Grid, Typography, ModalClose } from '@mui/joy'
import { GoogleDrive, KnowledgeSourceBtn } from './components'
import React, { useMemo } from 'react'
import { AddKnowledgeBaseModalProps, SourceType } from './types'
import { sources } from './const'

export default function AddKnowledgeBaseModal({ open, setOpen, isGoogleDriveConnected }: AddKnowledgeBaseModalProps) {
    const [selectedSourceType, setSelectedSourceType] = React.useState<SourceType | null>(null)
    const unconnectedSources = useMemo(
        () =>
            sources.filter((source) => {
                switch (source.sourceType) {
                    case SourceType.GoogleDrive:
                        return !isGoogleDriveConnected
                    default:
                        return true
                }
            }),
        [isGoogleDriveConnected]
    )

    const handleSourceClick = (sourceType: SourceType) => {
        if (sourceType === SourceType.GoogleDrive) {
            setSelectedSourceType(sourceType)
        }
    }

    const handleBack = () => {
        setSelectedSourceType(null)
    }

    return (
        <Modal
            aria-labelledby="Add knowledge base"
            open={open}
            onClose={() => {
                setOpen(false)
                setSelectedSourceType(null)
            }}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '.MuiSheet-root': {
                    outline: 'none',
                },
            }}
        >
            <Sheet
                variant="outlined"
                sx={{
                    maxWidth: 800,
                    borderRadius: 'md',
                    p: 3,
                    boxShadow: 'lg',
                }}
            >
                <ModalClose />
                {{
                    [SourceType.GoogleDrive]: <GoogleDrive onBack={handleBack} />,
                }[selectedSourceType || ''] || (
                    <>
                        <Typography component="h2" level="h4" textColor="inherit" fontWeight="lg">
                            Select Knowledge Base Source
                        </Typography>
                        <Typography component="p" textColor="text.tertiary" level="body-sm" mb={2}>
                            Add data to this agent's knowledge base.
                        </Typography>
                        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                            {unconnectedSources.map((source) => (
                                <KnowledgeSourceBtn
                                    key={source.name}
                                    source={source}
                                    onClick={() => !source.comingSoon && handleSourceClick(source.sourceType)}
                                />
                            ))}
                        </Grid>
                    </>
                )}
            </Sheet>
        </Modal>
    )
}
