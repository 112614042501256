import axios from 'axios'

// TelegramClient API class
export const TelegramClient = {
    connectTelegram: async (telegramId: string) => {
        try {
            const response = await axios.post('/integrations/connect/telegram', {
                telegramId,
            })

            if (!response.data.success) {
                throw new Error(response.data.message || 'Failed to connect Telegram')
            }

            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.response?.data?.message || 'Failed to connect Telegram')
            }
            throw error
        }
    },

    disconnectTelegram: async () => {
        try {
            const response = await axios.delete('/integrations/disconnect/telegram')

            if (!response.data.success) {
                throw new Error(response.data.message || 'Failed to disconnect Telegram')
            }

            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.response?.data?.message || 'Failed to disconnect Telegram')
            }
            throw error
        }
    },
}
