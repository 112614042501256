import axios from 'axios'

interface GoogleAccount {
    email: string
}

export const GoogleDriveClient = {
    connectGoogleDrive: async (code: string) => {
        try {
            const response = await axios.post('/integrations/gauth/connect', { code })

            if (!response.data.success) {
                throw new Error(response.data.message || 'Failed to connect Google Drive')
            }

            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.response?.data?.message || 'Failed to connect Google Drive')
            }
            throw error
        }
    },

    disconnectGoogleDrive: async (email: string) => {
        try {
            const response = await axios.delete(`/integrations/gauth/disconnect/${email}`)

            if (!response.data.success) {
                throw new Error(response.data.message || 'Failed to disconnect Google Drive')
            }

            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.response?.data?.message || 'Failed to disconnect Google Drive')
            }
            throw error
        }
    },

    getConnectedAccounts: async (): Promise<{ accounts: GoogleAccount[] }> => {
        try {
            const response = await axios.get('/integrations/gauth/accounts')
            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.response?.data?.message || 'Failed to fetch Google accounts')
            }
            throw error
        }
    },

    getAccessToken: async (email: string): Promise<string> => {
        try {
            const response = await axios.get(`/integrations/gauth/token/${email}`)
            console.log(response.data)
            return response.data.data.accessToken
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.response?.data?.message || 'Failed to get access token')
            }
            throw error
        }
    },

    addDocuments: async (email: string, documents: { id: string }[]) => {
        try {
            const response = await axios.post('/integrations/gdrive/docs', {
                email,
                documents,
            })

            if (!response.data.success) {
                throw new Error(response.data.message || 'Failed to add Google Drive documents')
            }

            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.response?.data?.message || 'Failed to add Google Drive documents')
            }
            throw error
        }
    },

    removeDocument: async (documentObjectId: string) => {
        try {
            const response = await axios.delete(`/integrations/gdrive/docs/${documentObjectId}`)

            if (!response.data.success) {
                throw new Error(response.data.message || 'Failed to remove Google Drive document')
            }

            return response.data
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(error.response?.data?.message || 'Failed to remove Google Drive document')
            }
            throw error
        }
    },
}
