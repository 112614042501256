import { Box, Button, Card, Chip, Grid, Stack, Typography } from '@mui/joy'
import { Check } from '@mui/icons-material'
import { useState } from 'react'
import { GoogleDriveDocumentDialog } from './components'
import { GoogleDriveIcon } from 'assets/icons/KnowledgeBaseIcons'

interface GoogleDriveConnectedCardProps {
    email: string
    disconnectGoogleDrive: (email: string) => Promise<void>
    loading: boolean
    onSuccess: (message: string) => void
}

export default function GoogleDriveConnectedCard({ email, disconnectGoogleDrive, loading, onSuccess }: GoogleDriveConnectedCardProps) {
    const [isDialogOpen, setIsDialogOpen] = useState(false)

    return (
        <Grid xs={12} sm={6} md={4}>
            <Card>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                    <GoogleDriveIcon sx={{ fontSize: 24, color: 'primary.500' }} />
                    <Typography level="title-lg">Google Drive</Typography>
                    <Chip size="sm" variant="soft" color="success" startDecorator={<Check />}>
                        Connected
                    </Chip>
                </Box>
                <Stack spacing={2}>
                    <Typography level="body-sm" color="neutral">
                        Connected Gmail: {email}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Button variant="outlined" color="danger" onClick={() => disconnectGoogleDrive(email)} loading={loading}>
                            Disconnect
                        </Button>
                        <Button variant="outlined" color="primary" onClick={() => setIsDialogOpen(true)}>
                            Documents
                        </Button>
                    </Box>
                </Stack>
            </Card>
            <GoogleDriveDocumentDialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} email={email} onSuccess={onSuccess} />
        </Grid>
    )
}
