import axios from 'axios'
import { TokenResponse } from './types'

export class DispatcherDashboardClient {
    static async getToken(): Promise<TokenResponse> {
        try {
            const response = await axios.get('/voip/twilio/browser-sdk/token', {
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            return response.data
        } catch (error) {
            throw new Error('Failed to get Twilio token')
        }
    }
}
