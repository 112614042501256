import React from 'react'
import Box from '@mui/joy/Box'
import Card from '@mui/joy/Card'
import CardContent from '@mui/joy/CardContent'
import Grid from '@mui/joy/Grid'
import { keyframes } from '@mui/system'

const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`

interface SkeletonProps {
    sx?: React.CSSProperties & {
        [key: string]: number | string
    }
}

const Skeleton: React.FC<SkeletonProps> = ({ sx = {} }) => (
    <Box
        sx={{
            bgcolor: 'neutral.softBg',
            animation: `${pulse} 1.5s ease-in-out infinite`,
            borderRadius: 'sm',
            ...sx,
        }}
    />
)

const EmailAccountSkeleton: React.FC = () => (
    <Grid container={false} xs={12} sm={6} md={4} lg={3}>
        <Card
            variant="outlined"
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <CardContent
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    p: 3,
                    gap: 2,
                }}
            >
                <Skeleton
                    sx={{
                        width: 48,
                        height: 48,
                        borderRadius: '50%',
                    }}
                />
                <Skeleton
                    sx={{
                        width: '70%',
                        height: 24,
                    }}
                />
                <Skeleton
                    sx={{
                        width: '90%',
                        height: 16,
                    }}
                />
                <Skeleton
                    sx={{
                        width: '40%',
                        height: 14,
                        mt: 'auto',
                        pt: 1,
                    }}
                />
            </CardContent>
        </Card>
    </Grid>
)

export default function NylasEmailAccountsSkeleton() {
    return (
        <Grid container spacing={3}>
            {[...Array(4)].map((_, index) => (
                <EmailAccountSkeleton key={index} />
            ))}
        </Grid>
    )
}
