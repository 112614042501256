import Box from '@mui/joy/Box'
import Card from '@mui/joy/Card'
import CardContent from '@mui/joy/CardContent'
import Typography from '@mui/joy/Typography'
import Grid from '@mui/joy/Grid'
import EmailIcon from '@mui/icons-material/Email'
import AddIcon from '@mui/icons-material/Add'
import { useColorScheme } from '@mui/joy/styles'
import IconButton from '@mui/joy/IconButton'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import CircularProgress from '@mui/joy/CircularProgress'
import { EmailAccount } from '../api/NylasEmailClient/types'

interface NylasEmailAccountsProps {
    accounts: EmailAccount[]
    onAddAccount: () => void
    onDisconnect: (account: EmailAccount) => Promise<void>
    isLoading?: boolean
}

export default function NylasEmailAccounts({ accounts, onAddAccount, onDisconnect, isLoading = false }: NylasEmailAccountsProps) {
    const { mode } = useColorScheme()

    const renderAccountCard = (account: EmailAccount) => (
        <Grid container={false} xs={12} sm={6} md={4} lg={3} key={account.id}>
            <Card
                variant="outlined"
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                    '&:hover': {
                        transform: 'translateY(-4px)',
                        boxShadow: 'md',
                    },
                    bgcolor: mode === 'dark' ? 'neutral.900' : 'background.surface',
                    position: 'relative',
                }}
            >
                <IconButton
                    variant="plain"
                    color="neutral"
                    size="sm"
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        zIndex: 3,
                        '&:hover': {
                            color: 'danger.500',
                        },
                    }}
                    onClick={() => onDisconnect(account)}
                >
                    <DeleteOutlineIcon />
                </IconButton>
                <CardContent
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        p: 3,
                        gap: 1,
                    }}
                >
                    <Box
                        sx={{
                            width: 48,
                            height: 48,
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            bgcolor: 'primary.softBg',
                            color: 'primary.solidBg',
                            mb: 1,
                        }}
                    >
                        <EmailIcon />
                    </Box>
                    <Typography
                        level="title-lg"
                        sx={{
                            textAlign: 'center',
                            display: '-webkit-box',
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                        }}
                    >
                        {account.provider || account.email.split('@')[0]}
                    </Typography>
                    <Typography
                        level="body-sm"
                        sx={{
                            textAlign: 'center',
                            color: 'neutral.500',
                            display: '-webkit-box',
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                        }}
                    >
                        {account.email}
                    </Typography>
                    <Typography
                        level="body-xs"
                        sx={{
                            mt: 'auto',
                            pt: 1,
                            color: 'primary.500',
                            textTransform: 'uppercase',
                            letterSpacing: '0.1em',
                        }}
                    >
                        {account.provider}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    )

    const renderAddCard = () => (
        <Grid container={false} xs={12} sm={6} md={4} lg={3}>
            <Card
                variant="outlined"
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    cursor: isLoading ? 'wait' : 'pointer',
                    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                    '&:hover': isLoading
                        ? {}
                        : {
                              transform: 'translateY(-4px)',
                              boxShadow: 'md',
                              '& .add-icon-button': {
                                  bgcolor: mode === 'dark' ? 'primary.700' : 'primary.100',
                              },
                          },
                    bgcolor: mode === 'dark' ? 'neutral.900' : 'background.surface',
                    opacity: isLoading ? 0.7 : 1,
                }}
                onClick={isLoading ? undefined : onAddAccount}
            >
                <CardContent
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: 3,
                        gap: 2,
                    }}
                >
                    <Box
                        className="add-icon-button"
                        sx={{
                            width: 56,
                            height: 56,
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            bgcolor: mode === 'dark' ? 'neutral.800' : 'neutral.100',
                            transition: 'background-color 0.2s ease-in-out',
                        }}
                    >
                        {isLoading ? <CircularProgress size="md" /> : <AddIcon sx={{ fontSize: 32, color: 'primary.solidBg' }} />}
                    </Box>
                    <Typography
                        level="title-lg"
                        sx={{
                            textAlign: 'center',
                            color: mode === 'dark' ? 'neutral.300' : 'neutral.700',
                        }}
                    >
                        {isLoading ? 'Connecting...' : 'Add Email Account'}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    )

    return (
        <Box sx={{ flexGrow: 1, p: 3 }}>
            <Grid container spacing={3}>
                {accounts.map(renderAccountCard)}
                {renderAddCard()}
            </Grid>
        </Box>
    )
}
