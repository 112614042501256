/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'

export const AUTH_TOKEN = 'accessToken'

const apiUrl = () => {
    const apiUrlParam = new URLSearchParams(window.location.search).get('apiUrl')
    if (apiUrlParam) {
        return apiUrlParam
    } else {
        const protocol = window.location.protocol === 'https:' ? 'https' : 'http'
        const apiUrl = `${protocol}://${process.env.REACT_APP_API_HOST}`
        return apiUrl
    }
}

export default function setupAxios(on401?: () => void) {
    console.log(`SetupAxios::API_URL :::: ${apiUrl()}`)
    axios.defaults.baseURL = `${apiUrl()}/v1` // This will be used for absolute requests
    axios.defaults.headers.Accept = 'application/json'
    axios.interceptors.request.use(
        (config: any) => {
            const accessToken = localStorage.getItem(AUTH_TOKEN)
            if (accessToken) {
                config.headers.Authorization = `Bearer ${accessToken}`
            }

            return config
        },
        (err: any) => Promise.reject(err)
    )
    axios.interceptors.response.use(
        (res: any) => res,
        (err: any) => {
            const status = err.status || err.response.status
            if (status === 401) {
                localStorage.removeItem(AUTH_TOKEN)
                on401?.()
            }
            return Promise.reject(err)
        }
    )
}
