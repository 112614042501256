import { Box, Chip, Tooltip, Typography } from '@mui/joy'
import { FetchedEmailObject } from 'pages/dashboard/pages/dispatcher/pages/dispatcher-emails/api/EmailThreadClient'

interface EmailHeaderProps {
    emailData: FetchedEmailObject
}

export default function EmailDetails({ emailData }: EmailHeaderProps) {
    return (
        <Box
            sx={{
                py: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
            }}
        >
            <Typography level="title-lg" textColor="text.primary">
                {emailData.subject}
            </Typography>
            <Box
                sx={{
                    mt: 1,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    flexWrap: 'wrap',
                }}
            >
                <div>
                    <Typography component="span" level="body-sm" sx={{ mr: 1, display: 'inline-block' }}>
                        From
                    </Typography>
                    <Tooltip size="sm" title="Copy email" variant="outlined">
                        <Chip size="sm" variant="soft" color="primary" onClick={() => {}}>
                            {emailData.from[0]?.email}
                        </Chip>
                    </Tooltip>
                </div>
                <div>
                    <Typography component="span" level="body-sm" sx={{ mr: 1, display: 'inline-block' }}>
                        to
                    </Typography>
                    <Tooltip size="sm" title="Copy email" variant="outlined">
                        <Chip size="sm" variant="soft" color="primary" onClick={() => {}}>
                            {emailData.to[0]?.email}
                        </Chip>
                    </Tooltip>
                </div>
            </Box>
        </Box>
    )
}
