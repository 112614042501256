import { loadGoogleApiScripts, loadGoogleIdentityScripts } from '../../../../../../../../../components/integrations/Google/google.script.loader'

export const DEFAULT_GOOGLE_OAUTH_SCOPES = [
    'https://www.googleapis.com/auth/drive.readonly',
    'https://www.googleapis.com/auth/drive.metadata.readonly',
    'https://www.googleapis.com/auth/userinfo.email',
] as const

class GoogleOAuthService {
    private static _instance: GoogleOAuthService | null = null
    private isInitialized = false

    private constructor() {
        console.log(process.env.REACT_APP_GOOGLE_CLIENT_ID, 'process.env.REACT_APP_GOOGLE_CLIENT_ID')
    }

    public static getInstance(): GoogleOAuthService {
        if (!GoogleOAuthService._instance) {
            GoogleOAuthService._instance = new GoogleOAuthService()
        }
        return GoogleOAuthService._instance
    }

    private validateCredentials(): { clientId: string } {
        const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID
        if (!clientId) {
            throw new Error('Google client ID is not configured')
        }
        return { clientId }
    }

    private async initializeGoogleAuth(): Promise<void> {
        if (this.isInitialized) {
            return
        }

        try {
            await loadGoogleIdentityScripts()
            await loadGoogleApiScripts()
            this.isInitialized = true
        } catch (error) {
            throw new Error('Failed to load Google authentication script')
        }
    }

    private generateState(): string {
        const array = new Uint32Array(8)
        window.crypto.getRandomValues(array)
        return Array.from(array, (x) => x.toString(16)).join('')
    }

    /**
     * Initiates the Google OAuth2 authentication flow in a popup window. This won't redirect the user to another page by destroying the current page and its state.
     * This method will load the Google authentication script if not already loaded,
     * and handle the OAuth2 code flow with the specified scopes.
     *
     * @param callbacks - Object containing callback functions for handling the OAuth flow
     * @param scopes - Optional array of OAuth scopes to request. If not provided, uses DEFAULT_GOOGLE_OAUTH_SCOPES     *
     * @throws {Error} If Google client ID is not configured
     * @throws {Error} If Google authentication script fails to load
     */
    public async requestAuthCode(
        callbacks: {
            onSuccess: (response: google.accounts.oauth2.CodeResponse, deniedScopes: string[]) => void
            onError: (error: { type: string; message: string }) => void
        },
        scopes: string[] | undefined = undefined
    ): Promise<void> {
        try {
            await this.initializeGoogleAuth()
            const { clientId } = this.validateCredentials()
            const state = this.generateState()
            const adjustedScopes = scopes?.join(' ') || DEFAULT_GOOGLE_OAUTH_SCOPES.join(' ')
            const client = google.accounts.oauth2.initCodeClient({
                client_id: clientId,
                scope: adjustedScopes,
                ux_mode: 'popup',
                callback: (response) => {
                    if (response.error) {
                        callbacks.onError?.({ type: 'auth_error', message: `${response.error}: ${response.error_description || 'Unknown error'}` })
                        return
                    }

                    // Get granted scopes from the response
                    const grantedScopes = response.scope?.split(' ') || []

                    // Compare with requested scopes to find which ones were denied
                    const requestedScopes = adjustedScopes.split(' ')
                    const deniedScopes = requestedScopes.filter((scope) => !grantedScopes.includes(scope))

                    callbacks.onSuccess(response, deniedScopes)
                },
                state,
                error_callback: (error) => {
                    let errorMessage = 'An error occurred during authentication'
                    if (error.type === 'popup_failed_to_open') {
                        errorMessage = 'Failed to open Google authentication popup. Please check if popups are blocked.'
                    } else if (error.type === 'popup_closed') {
                        errorMessage = 'Authentication cancelled'
                    }

                    callbacks.onError?.({ type: error.type, message: errorMessage })
                },
            })

            client.requestCode()
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : 'Failed to initialize Google authentication'
            callbacks.onError?.({
                type: 'initialization_failed',
                message: errorMessage,
            })
        }
    }
}

export const googleOAuthService = GoogleOAuthService.getInstance()
