import { ResetPasswordData, resetPasswordSchema } from '@numeo/types'
import { ref, string } from 'yup'

export const resetValidationSchema = resetPasswordSchema.shape({
    confirm: string()
        .required('Please confirm your password')
        .oneOf([ref('password')], 'Passwords must match'),
})

export type ResetPasswordFormData = ResetPasswordData & { confirm: string }
