import { Navigate, Route, Routes } from 'react-router-dom'
import { DispatcherDashboardPage, DispatcherEmailsPage, DispatcherSettingsPage } from './pages'

export default function DispatcherRoutes() {
    return (
        <Routes>
            <Route index element={<DispatcherDashboardPage />} />
            <Route path="settings" element={<DispatcherSettingsPage />} />
            <Route path="emails" element={<DispatcherEmailsPage />} />
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    )
}
