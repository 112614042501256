export enum ConversationBackend {
    OpenAiRealtimeV1 = 'Open AI, Multimodal Realtime V1',
    LLAMA_3b = 'Meta LLAMA 3b',
    CLAUDE_SONET = 'CLAUDE SONET',
}

export enum VoicePipeline {
    ElevenLabs = 'ElevenLabs',
    OpenAiRealtimeV1 = 'Open AI, Multimodal Realtime V1',
    WhisperElevenLabs = 'Whisper stt -> ElevenLabs tts',
}
