import { ApplicationOrder, FetchedEmailAttachment, FetchedEmailParticipant, GenerateEmailResponse } from '@numeo/types'
import axios, { AxiosError } from 'axios'

export interface FetchedEmailObject {
    id: string
    thread_id: string
    subject: string
    snippet: string
    body: string
    from: FetchedEmailParticipant[]
    to: FetchedEmailParticipant[]
    reply_to: FetchedEmailParticipant[]
    date: number
    unread: boolean
    attachments: FetchedEmailAttachment[]
}

export interface FetchedEmailData {
    application_id: string
    object: FetchedEmailObject
}

export interface FetchedEmail {
    _id: string
    applicationId: string
    emailData: FetchedEmailData
    replyCount?: number
}

export interface PaginatedResponse<T> {
    threads: T[]
    pagination: {
        total: number
        page: number
        limit: number
        pages: number
    }
}

class ApiError extends Error {
    constructor(
        message: string,
        public status?: number
    ) {
        super(message)
        this.name = 'ApiError'
    }
}

export const EmailThreadClient = {
    async getEmailThreads(page: number = 1, limit: number = 20): Promise<PaginatedResponse<FetchedEmail>> {
        try {
            const { data } = await axios.get<PaginatedResponse<FetchedEmail>>(`/email/fetched-emails`, {
                params: { page, limit },
            })
            return data
        } catch (error) {
            if (error instanceof AxiosError && error.response?.status === 404) {
                throw new ApiError('Email threads not found', 404)
            }
            throw new ApiError('Failed to fetch email threads')
        }
    },

    async getThreadRepliesAndDetails(threadId: string): Promise<{ threads: FetchedEmail[]; order: ApplicationOrder }> {
        try {
            const response = await axios.get(`/email/fetched-emails/${threadId}`)
            return response.data
        } catch (error) {
            if (error instanceof AxiosError) {
                throw new ApiError(error.response?.data?.message || 'Failed to fetch thread replies', error.response?.status)
            }
            throw error
        }
    },

    async parseEmailOrder(emailId: string): Promise<ApplicationOrder> {
        try {
            const response = await axios.post(`/orders/parse-email/${emailId}`)

            return response.data.data
        } catch (error) {
            if (error instanceof AxiosError) {
                throw new ApiError(error.response?.data?.message || 'Failed to parse email order', error.response?.status)
            }
            throw error
        }
    },

    async generateEmailOfferById(
        orderId: string,
        options?: {
            tone: 'official' | 'dispatcher-style'
            pricePerMile?: number
        }
    ): Promise<GenerateEmailResponse['data']> {
        try {
            const response = await axios.post(`/orders/generate-email/${orderId}`, options)
            return response.data.data
        } catch (error) {
            if (error instanceof AxiosError) {
                throw new ApiError(error.response?.data?.message || 'Failed to generate email offer', error.response?.status)
            }
            throw error
        }
    },

    async sendOfferEmail(payload: { orderId: string; subject: string; body: string }): Promise<void> {
        try {
            await axios.post(`/orders/send-offer-email/${payload.orderId}`, {
                subject: payload.subject,
                body: payload.body,
            })
        } catch (error) {
            if (error instanceof AxiosError) {
                throw new ApiError(error.response?.data?.message || 'Failed to send offer email', error.response?.status)
            }
            throw error
        }
    },
}
