/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Card, Input, Typography } from '@mui/joy'
import { Send } from '@mui/icons-material'
import { useState, useEffect } from 'react'
import { RichTextEditor } from 'components/common'
import { EmailThreadClient } from 'pages/dashboard/pages/dispatcher/pages/dispatcher-emails/api/EmailThreadClient'
import { ApplicationOrderOffer } from '@numeo/types'
import { useSnackbar, ClickAwayListener } from '@mui/base'

interface OfferPreviewProps {
    offerEmail: ApplicationOrderOffer
    orderId: string
    onEmailSend: () => void
}

export default function OfferPreview({ offerEmail, orderId, onEmailSend }: OfferPreviewProps) {
    const [loading, setLoading] = useState(false)
    const [editedSubject, setEditedSubject] = useState(offerEmail.subject)
    const [editedBody, setEditedBody] = useState(offerEmail.content)
    const [open, setOpen] = useState(false)
    const [toastMessage, setToastMessage] = useState('')
    const [toastColor, setToastColor] = useState<'success' | 'danger'>('success')

    const handleClose = () => {
        setOpen(false)
    }

    const { getRootProps, onClickAway } = useSnackbar({
        onClose: handleClose,
        open,
        autoHideDuration: 5000,
    })

    const showToast = (message: string, color: 'success' | 'danger') => {
        setToastMessage(message)
        setToastColor(color)
        setOpen(true)
    }

    useEffect(() => {
        setEditedSubject(offerEmail.subject)
        setEditedBody(offerEmail.content)
    }, [offerEmail])

    const sendOfferEmail = async () => {
        setLoading(true)
        try {
            const payload = {
                orderId,
                subject: editedSubject,
                body: editedBody,
            }
            await EmailThreadClient.sendOfferEmail(payload)
            onEmailSend()
            showToast('Sent to Email successfully', 'success')
        } catch (error: any) {
            const errorMessage = error?.response?.data?.message || error?.message || 'Failed to send to Email'
            showToast(errorMessage, 'danger')
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <Card variant="outlined" sx={{ p: 3 }}>
                <Typography level="title-lg" startDecorator={<Send />} sx={{ mb: 3 }}>
                    Generated Offer
                </Typography>

                <Box sx={{ mb: 3 }}>
                    <Typography level="title-sm" sx={{ mb: 1, color: 'neutral.500' }}>
                        Subject
                    </Typography>
                    <Input
                        value={editedSubject}
                        onChange={(e) => setEditedSubject(e.target.value)}
                        variant="soft"
                        sx={{
                            mb: 1,
                            px: 2.5,
                            py: 1,
                            '--Input-minHeight': '45px',
                            '--Input-paddingInline': '1rem',
                            bgcolor: 'background.level1',
                        }}
                    />
                </Box>

                <Box>
                    <Typography level="title-sm" sx={{ mb: 1, color: 'neutral.500' }}>
                        Message
                    </Typography>
                    <RichTextEditor value={editedBody} onChange={setEditedBody} minHeight={200} showToolbar />
                </Box>

                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button loading={loading} onClick={sendOfferEmail} startDecorator={<Send />} size="lg">
                        Send
                    </Button>
                </Box>
            </Card>

            {open && (
                <ClickAwayListener onClickAway={onClickAway}>
                    <div
                        {...getRootProps()}
                        style={{
                            position: 'fixed',
                            bottom: 16,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            backgroundColor: toastColor === 'danger' ? '#d32f2f' : '#2e7d32',
                            color: 'white',
                            padding: '6px 16px',
                            borderRadius: 4,
                            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
                            zIndex: 1400,
                        }}
                    >
                        {toastMessage}
                        <button
                            onClick={handleClose}
                            style={{
                                marginLeft: 8,
                                background: 'none',
                                border: 'none',
                                color: 'white',
                                cursor: 'pointer',
                                padding: '4px',
                            }}
                        >
                            ✕
                        </button>
                    </div>
                </ClickAwayListener>
            )}
        </>
    )
}
