import { SignUpData, signUpSchema } from '@numeo/types'
import { object, ref, string } from 'yup'

// Extend the signup schema to include password confirmation
export const signUpValidationSchema = signUpSchema.concat(
    object({
        confirm: string()
            .required('Please confirm your password')
            .oneOf([ref('password')], 'Passwords must match'),
    })
)

export type RegisterFormData = SignUpData & { confirm: string }
