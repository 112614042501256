import { Box, Grid, Typography } from '@mui/joy'
import { useState } from 'react'
import { GoogleDriveClient } from './api/GoogleDriveClient'
import { AddKnowledgeBaseBtn, AddKnowledgeBaseModal, GoogleDriveConnectedCard } from './components'
import { useSnackbar, ClickAwayListener } from '@mui/base'
import { useAuth } from 'hooks/auth'

export default function DispatcherKnowledgeBase() {
    const [openAddSourceModal, setOpenAddSourceModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [disconnectingEmail, setDisconnectingEmail] = useState<string | null>(null)

    const { application, setApplication } = useAuth()
    const connectedDrives = application?.connectors?.knowledgeBase?.googleDrives || []

    const [open, setOpen] = useState(false)
    const [toastMessage, setToastMessage] = useState('')
    const [toastColor, setToastColor] = useState<'success' | 'danger'>('success')

    const handleClose = () => {
        setOpen(false)
    }

    const { getRootProps, onClickAway } = useSnackbar({
        onClose: handleClose,
        open,
        autoHideDuration: 5000,
    })

    const showToast = (message: string, color: 'success' | 'danger') => {
        setToastMessage(message)
        setToastColor(color)
        setOpen(true)
    }

    const disconnectGoogleDrive = async (email: string) => {
        try {
            setLoading(true)
            setDisconnectingEmail(email)
            const response = await GoogleDriveClient.disconnectGoogleDrive(email)

            if (response.success) {
                setApplication(response.data)
                showToast('Google Drive disconnected successfully', 'success')
            }
        } catch (error) {
            console.error('Failed to disconnect Google Drive:', error)
            showToast('Failed to disconnect Google Drive', 'danger')
        } finally {
            setLoading(false)
            setDisconnectingEmail(null)
        }
    }

    return (
        <Box sx={{ p: 2, mb: 20 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography level="h1">Knowledge Base</Typography>
            </Box>
            <Box sx={{ flexGrow: 1, p: 3 }}>
                <Grid container spacing={3}>
                    {connectedDrives.map((drive) => (
                        <GoogleDriveConnectedCard
                            key={drive.email}
                            email={drive.email}
                            disconnectGoogleDrive={disconnectGoogleDrive}
                            loading={loading && disconnectingEmail === drive.email}
                            onSuccess={(message) => showToast(message, 'success')}
                        />
                    ))}
                    <AddKnowledgeBaseBtn onClick={() => setOpenAddSourceModal(true)} isLoading={loading} />
                </Grid>
            </Box>

            <AddKnowledgeBaseModal
                open={openAddSourceModal}
                setOpen={setOpenAddSourceModal}
                isGoogleDriveConnected={false} // Always allow adding new Google Drive connections
            />

            {open && (
                <ClickAwayListener onClickAway={onClickAway}>
                    <div
                        {...getRootProps()}
                        style={{
                            position: 'fixed',
                            bottom: 16,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            backgroundColor: toastColor === 'danger' ? '#d32f2f' : '#2e7d32',
                            color: 'white',
                            padding: '6px 16px',
                            borderRadius: 4,
                            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
                            zIndex: 1400,
                        }}
                    >
                        {toastMessage}
                        <button
                            onClick={handleClose}
                            style={{
                                marginLeft: 8,
                                background: 'none',
                                border: 'none',
                                color: 'white',
                                cursor: 'pointer',
                                padding: '4px',
                            }}
                        >
                            ✕
                        </button>
                    </div>
                </ClickAwayListener>
            )}
        </Box>
    )
}
