import SvgIcon, { SvgIconProps } from '@mui/joy/SvgIcon'

export function DropboxIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path fill="#2355F7" d="M0 0h24v24H0z" />
            <g clipPath="url(#a)">
                <path
                    fill="#fff"
                    d="M8.651 4.3 4 7.314l3.237 2.605 4.651-2.903L8.651 4.3ZM4 12.449 8.651 15.5l3.237-2.716-4.65-2.865L4 12.449Zm7.888.335 3.275 2.716 4.614-3.014-3.2-2.567-4.689 2.865Zm7.889-5.47L15.163 4.3l-3.275 2.716 4.689 2.903 3.2-2.605Zm-7.851 6.065L8.65 16.095l-1.377-.93v1.042l4.652 2.79 4.65-2.79v-1.042l-1.413.93-3.237-2.716Z"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M4 4.3h16v14.884H4z" />
                </clipPath>
            </defs>
        </SvgIcon>
    )
}

export function GoogleDriveIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path fill="#fff" d="M0 0h24v24H0z" />
            <g clipPath="url(#a)">
                <path fill="#0066DA" d="m4.36 17.784.795 1.37c.165.29.402.516.68.681l2.835-4.907H3c0 .32.082.639.247.928l1.114 1.928Z" />
                <path fill="#00AC47" d="M12 9.155 9.165 4.247a1.872 1.872 0 0 0-.68.68L3.247 14a1.868 1.868 0 0 0-.247.928h5.67L12 9.155Z" />
                <path
                    fill="#EA4335"
                    d="M18.165 19.835c.278-.165.515-.392.68-.68l.33-.567 1.578-2.732c.165-.289.247-.609.247-.928h-5.67l1.206 2.371 1.629 2.536Z"
                />
                <path fill="#00832D" d="m12 9.155 2.835-4.908A1.813 1.813 0 0 0 13.907 4h-3.814c-.33 0-.65.093-.928.247L12 9.155Z" />
                <path fill="#2684FC" d="M15.33 14.928H8.67l-2.835 4.907c.278.165.598.247.928.247h10.474c.33 0 .65-.092.928-.247l-2.835-4.907Z" />
                <path fill="#FFBA00" d="m18.134 9.464-2.619-4.536a1.873 1.873 0 0 0-.68-.68L12 9.154l3.33 5.773h5.66c0-.32-.083-.64-.248-.928l-2.608-4.536Z" />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M3 4h18v16.082H3z" />
                </clipPath>
            </defs>
        </SvgIcon>
    )
}

export function NotionIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props} viewBox="0 0 15 15" sx={{ background: '#fff', ...(props.sx || {}) }}>
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M3.25781 3.11684C3.67771 3.45796 3.83523 3.43193 4.62369 3.37933L12.0571 2.93299C12.2147 2.93299 12.0836 2.77571 12.0311 2.74957L10.7965 1.85711C10.56 1.67347 10.2448 1.46315 9.64083 1.51576L2.44308 2.04074C2.18059 2.06677 2.12815 2.19801 2.2327 2.30322L3.25781 3.11684ZM3.7041 4.84917V12.6704C3.7041 13.0907 3.91415 13.248 4.38693 13.222L12.5562 12.7493C13.0292 12.7233 13.0819 12.4341 13.0819 12.0927V4.32397C13.0819 3.98306 12.9508 3.79921 12.6612 3.82545L4.12422 4.32397C3.80918 4.35044 3.7041 4.50803 3.7041 4.84917ZM11.7688 5.26872C11.8212 5.50518 11.7688 5.74142 11.5319 5.76799L11.1383 5.84641V11.6205C10.7965 11.8042 10.4814 11.9092 10.2188 11.9092C9.79835 11.9092 9.69305 11.7779 9.37812 11.3844L6.80345 7.34249V11.2532L7.61816 11.437C7.61816 11.437 7.61816 11.9092 6.96086 11.9092L5.14879 12.0143C5.09615 11.9092 5.14879 11.647 5.33259 11.5944L5.80546 11.4634V6.29276L5.1489 6.24015C5.09625 6.00369 5.22739 5.66278 5.5954 5.63631L7.53935 5.50528L10.2188 9.5998V5.97765L9.53564 5.89924C9.4832 5.61018 9.69305 5.40028 9.95576 5.37425L11.7688 5.26872ZM1.83874 1.33212L9.32557 0.780787C10.245 0.701932 10.4815 0.754753 11.0594 1.17452L13.4492 2.85424C13.8436 3.14309 13.975 3.22173 13.975 3.53661V12.7493C13.975 13.3266 13.7647 13.6681 13.0293 13.7203L4.33492 14.2454C3.78291 14.2717 3.52019 14.193 3.23111 13.8253L1.47116 11.5419C1.1558 11.1216 1.02466 10.8071 1.02466 10.4392V2.25041C1.02466 1.77825 1.23504 1.38441 1.83874 1.33212Z"
                fill="#000000"
            />
        </SvgIcon>
    )
}

export function OneDriveIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path fill="#fff" d="M0 0h24v24H0z" />
            <g clipPath="url(#a)">
                <path fill="#0364B8" d="m10.101 9.846 3.36 2.012 2-.843a3.24 3.24 0 0 1 1.508-.257 5 5 0 0 0-9.02-1.507L8 9.25a3.98 3.98 0 0 1 2.101.596Z" />
                <path
                    fill="#0078D4"
                    d="M10.101 9.846A3.98 3.98 0 0 0 8 9.25l-.051.001a3.998 3.998 0 0 0-3.23 6.285L7.68 14.29l1.316-.554 2.932-1.234 1.531-.644-3.359-2.012Z"
                />
                <path fill="#1490DF" d="M16.97 10.758a3.24 3.24 0 0 0-1.508.258l-2.002.842.58.347 1.903 1.14.83.497 2.838 1.7a3.25 3.25 0 0 0-2.642-4.784Z" />
                <path
                    fill="#28A8EA"
                    d="m16.773 13.842-.83-.497-1.902-1.14-.58-.347-1.532.644-2.932 1.234-1.316.554-2.962 1.246A3.994 3.994 0 0 0 8 17.25h8.75a3.248 3.248 0 0 0 2.861-1.708l-2.838-1.7Z"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M4 7h16v10.25H4z" />
                </clipPath>
            </defs>
        </SvgIcon>
    )
}

export function IntercomIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path fill="#1F8DED" d="M0 .95h23.796v23.795H0z" />
            <path
                fill="#fff"
                d="M18.442 13.736a.595.595 0 0 1-1.19 0v-5.35a.595.595 0 0 1 1.19 0v5.35Zm-.207 3.724c-.092.078-2.296 1.928-6.336 1.928-4.041 0-6.245-1.85-6.337-1.929a.595.595 0 0 1 .773-.904c.035.03 2.003 1.643 5.564 1.643 3.605 0 5.542-1.625 5.561-1.642a.595.595 0 0 1 .775.904ZM5.355 8.385a.595.595 0 0 1 1.19 0v5.35a.595.595 0 0 1-1.19 0v-5.35Zm2.974-1.19a.595.595 0 0 1 1.19 0v7.948a.595.595 0 0 1-1.19 0V7.195Zm2.975-.3a.595.595 0 0 1 1.19 0v8.626a.595.595 0 0 1-1.19 0V6.894Zm2.974.3a.595.595 0 0 1 1.19 0v7.948a.595.595 0 0 1-1.19 0V7.195Z"
            />
        </SvgIcon>
    )
}

export function FreshdeskIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path fill="#fff" d="M0 0h24v24H0z" />
            <path
                fill="#25C16F"
                d="M11.972 4h6.01A2 2 0 0 1 20 6.018v6.01A7.975 7.975 0 0 1 12.027 20h-.045a7.976 7.976 0 0 1-7.374-4.92A7.97 7.97 0 0 1 4 12.03c0-4.419 3.564-7.982 7.973-8.027V4Z"
            />
            <path
                fill="#fff"
                d="M11.972 7.564a3.664 3.664 0 0 0-3.663 3.663v2.491a1.247 1.247 0 0 0 1.218 1.219h1.037v-2.859h-1.4v-.8a2.845 2.845 0 0 1 5.682 0v.8h-1.418v2.859h.936v.045a1.145 1.145 0 0 1-1.127 1.127h-1.119c-.09 0-.19.046-.19.137a.204.204 0 0 0 .19.19h1.127a1.464 1.464 0 0 0 1.455-1.454v-.09a1.205 1.205 0 0 0 .936-1.183v-2.436c.051-2.064-1.585-3.71-3.664-3.71Z"
            />
        </SvgIcon>
    )
}

export function ZendeskIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path fill="#03363D" d="M0 0h24v24H0z" />
            <path
                fill="#fff"
                d="M11.39 9.073v8.563H4l7.39-8.563Zm0-3.073c0 .94-.389 1.843-1.082 2.508a3.775 3.775 0 0 1-2.613 1.038c-.98 0-1.92-.373-2.613-1.038A3.475 3.475 0 0 1 4 6h7.39Zm1.218 11.636c0-.94.39-1.842 1.082-2.507a3.776 3.776 0 0 1 2.613-1.039c.98 0 1.92.374 2.613 1.039a3.475 3.475 0 0 1 1.082 2.507h-7.39Zm0-3.072V6H20l-7.392 8.563Z"
            />
        </SvgIcon>
    )
}

export function FileIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <rect width="24" height="24" fill="url(#paint0_linear_6028_6552)" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 4.5H8.25C7.00736 4.5 6 5.50736 6 6.75V17.25C6 18.4926 7.00736 19.5 8.25 19.5H15.75C16.9926 19.5 18 18.4926 18 17.25V10.5H14.25C13.0074 10.5 12 9.49264 12 8.25V4.5ZM9 13.5C9 13.0858 9.33579 12.75 9.75 12.75H12C12.4142 12.75 12.75 13.0858 12.75 13.5C12.75 13.9142 12.4142 14.25 12 14.25H9.75C9.33579 14.25 9 13.9142 9 13.5ZM9.75 15.75C9.33579 15.75 9 16.0858 9 16.5C9 16.9142 9.33579 17.25 9.75 17.25H14.625C15.0392 17.25 15.375 16.9142 15.375 16.5C15.375 16.0858 15.0392 15.75 14.625 15.75H9.75Z"
                fill="white"
            />
            <path d="M17.5607 9L13.5 4.93934V8.25C13.5 8.66421 13.8358 9 14.25 9H17.5607Z" fill="white" />
            <defs>
                <linearGradient id="paint0_linear_6028_6552" x1="24" y1="-0.999999" x2="-1" y2="27" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A79EB1" />
                    <stop offset="1" stopColor="#615F65" />
                </linearGradient>
            </defs>
        </SvgIcon>
    )
}

export function TextIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <rect width="24" height="24" fill="url(#paint0_linear_6028_6560)"></rect>
            <path
                d="M6 6.16699H19.3333M6 12.0003H12.6667M6 17.8337H19.3333"
                stroke="white"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            ></path>
            <defs>
                <linearGradient id="paint0_linear_6028_6560" x1="24" y1="-0.999999" x2="-1" y2="27" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A79EB1"></stop>
                    <stop offset="1" stopColor="#615F65"></stop>
                </linearGradient>
            </defs>
        </SvgIcon>
    )
}

export function WebsiteIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path fill="url(#a)" d="M0 0h24v24H0z" />
            <path
                fill="#fff"
                d="M13.025 4.894c-.3-.318-.534-.394-.692-.394-.157 0-.391.076-.692.394-.302.32-.613.827-.891 1.523-.485 1.211-.822 2.87-.9 4.75h4.966c-.078-1.88-.415-3.539-.9-4.75-.278-.696-.588-1.203-.89-1.523ZM14.816 12.833H9.85c.078 1.88.415 3.539.9 4.75.278.696.589 1.203.891 1.523.3.318.535.394.692.394.158 0 .392-.076.692-.394.303-.32.613-.827.891-1.523.485-1.211.822-2.87.9-4.75ZM8.182 11.167c.079-2.056.446-3.932 1.02-5.369.29-.726.647-1.372 1.073-1.875a8.34 8.34 0 0 0-6.234 7.244h4.141ZM4.041 12.833h4.141c.079 2.056.446 3.932 1.02 5.37.29.725.647 1.371 1.073 1.874a8.34 8.34 0 0 1-6.234-7.244ZM16.484 12.833h4.141a8.34 8.34 0 0 1-6.233 7.244c.425-.503.782-1.15 1.072-1.875.575-1.437.941-3.313 1.02-5.369ZM20.625 11.167a8.34 8.34 0 0 0-6.233-7.244c.425.503.782 1.15 1.072 1.875.575 1.437.941 3.313 1.02 5.369h4.141Z"
            />
            <defs>
                <linearGradient id="a" x1={24} x2={-1} y1={-1} y2={27} gradientUnits="userSpaceOnUse">
                    <stop stopColor="#A79EB1" />
                    <stop offset={1} stopColor="#615F65" />
                </linearGradient>
            </defs>
        </SvgIcon>
    )
}
