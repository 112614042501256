import { object, string } from 'yup'
import { UpdateUserRequest } from './types'

// Yup validation schema
export const updateUserSchema = object<UpdateUserRequest>({
    userId: string()
        .required('User ID is required')
        .matches(/^[0-9a-fA-F]{24}$/, 'Invalid user ID format'),
    telegramId: string().nullable(),
    name: string().min(2, 'Name must be at least 2 characters').nullable(),
})
