import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { DispatcherRoutes, SettingsPage } from './pages'
import { useAuth } from 'hooks/auth'

export default function DashboardRoutes() {
    const { isAuthenticated } = useAuth()
    const location = useLocation()

    if (!isAuthenticated) {
        // Save the attempted URL for redirecting after login
        return <Navigate to="/auth/sign-in" state={{ from: location }} replace />
    }

    return (
        <Routes>
            <Route index element={<Navigate to="dispatcher" replace />} />
            <Route path="settings" element={<SettingsPage />} />
            <Route path="dispatcher/*" element={<DispatcherRoutes />} />
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    )
}
