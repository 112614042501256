import {
    DropboxIcon,
    FileIcon,
    FreshdeskIcon,
    GoogleDriveIcon,
    IntercomIcon,
    NotionIcon,
    OneDriveIcon,
    TextIcon,
    WebsiteIcon,
    ZendeskIcon,
} from 'assets/icons/KnowledgeBaseIcons'
import { SourceOption, SourceType } from './types'

export const sources: SourceOption[] = [
    { name: 'Google Drive', Icon: GoogleDriveIcon, sourceType: SourceType.GoogleDrive },
    { name: 'Files', Icon: FileIcon, comingSoon: true, sourceType: SourceType.Files },
    { name: 'Text', Icon: TextIcon, comingSoon: true, sourceType: SourceType.Text },
    { name: 'Website', Icon: WebsiteIcon, comingSoon: true, sourceType: SourceType.Website },
    { name: 'OneDrive', Icon: OneDriveIcon, comingSoon: true, sourceType: SourceType.OneDrive },
    { name: 'Dropbox', Icon: DropboxIcon, comingSoon: true, sourceType: SourceType.Dropbox },
    { name: 'Notion', Icon: NotionIcon, comingSoon: true, sourceType: SourceType.Notion },
    { name: 'Intercom', Icon: IntercomIcon, comingSoon: true, sourceType: SourceType.Intercom },
    { name: 'Freshdesk', Icon: FreshdeskIcon, comingSoon: true, sourceType: SourceType.Freshdesk },
    { name: 'Zendesk', Icon: ZendeskIcon, comingSoon: true, sourceType: SourceType.Zendesk },
]
