import { Box, IconButton, Typography, Divider } from '@mui/joy'
import { KeyboardArrowDownRounded } from '@mui/icons-material'
import { Virtuoso } from 'react-virtuoso'
import EmailCard from './EmailCard'
import { useCallback, useEffect, useState } from 'react'
import { EmailThreadClient, FetchedEmail } from '../../../api/EmailThreadClient'

const LIMIT = 10

interface EmailSectionProps {
    title: string
    selectedThreadId: string | null
    onThreadSelect?: (thread: FetchedEmail) => void
    emptyStateMessage?: string
}

export default function EmailSection({ title, selectedThreadId, onThreadSelect, emptyStateMessage = 'No messages' }: EmailSectionProps) {
    const [isExpanded, setIsExpanded] = useState(true)
    const [loading, setLoading] = useState(false)
    const [hasNextPage, setHasNextPage] = useState(true)
    const [page, setPage] = useState(1)
    const [emails, setEmails] = useState<FetchedEmail[]>([])
    const [totalCount, setTotalCount] = useState(0)

    const loadMoreItems = useCallback(async () => {
        if (loading || !hasNextPage) return

        try {
            setLoading(true)
            const response = await EmailThreadClient.getEmailThreads(page, LIMIT)
            setEmails((prev) => {
                const newThreadIds = new Set(response.threads.map((t) => t.emailData.object.thread_id))
                const uniquePrev = prev.filter((p) => !newThreadIds.has(p.emailData.object.thread_id))
                return [...uniquePrev, ...response.threads]
            })
            setTotalCount(response.pagination.total)
            setHasNextPage(page < response.pagination.pages)
            setPage((p) => p + 1)
        } catch (error) {
            console.error('Failed to fetch emails:', error)
        } finally {
            setLoading(false)
        }
    }, [loading, hasNextPage, page])

    // Initial load
    useEffect(() => {
        if (page === 1) {
            loadMoreItems()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // Only on mount

    const handleToggle = () => {
        setIsExpanded(!isExpanded)
    }

    const handleThreadSelect = useCallback(
        (thread: FetchedEmail) => {
            if (onThreadSelect) {
                onThreadSelect(thread)
            }
        },
        [onThreadSelect]
    )

    return (
        <>
            <Box
                onClick={handleToggle}
                sx={{
                    px: 2,
                    py: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                    '&:hover': {
                        bgcolor: 'background.level1',
                    },
                }}
            >
                {selectedThreadId ? null : (
                    <Typography level="title-sm" textColor="text.tertiary">
                        {title} ({totalCount})
                    </Typography>
                )}
                <IconButton
                    size="sm"
                    variant="plain"
                    color="primary"
                    sx={{
                        '--IconButton-size': '24px',
                        transform: isExpanded ? 'rotate(0deg)' : 'rotate(90deg)',
                        transition: 'transform 0.2s',
                        pointerEvents: 'none',
                    }}
                >
                    <KeyboardArrowDownRounded fontSize="small" color="primary" />
                </IconButton>
            </Box>
            {isExpanded &&
                (emails.length ? (
                    <Box sx={{ height: 'calc(100vh - 158px)', overflowY: 'auto' }}>
                        <Virtuoso
                            style={{ height: '100%', overflowY: 'auto', overflowX: 'hidden' }}
                            totalCount={totalCount}
                            overscan={10}
                            data={emails}
                            endReached={loadMoreItems}
                            components={{
                                Item: ({ children, ...props }) => <Box {...props}>{children}</Box>,
                                Footer: () => (
                                    <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
                                        <Typography level="body-sm" textColor="text.tertiary">
                                            {selectedThreadId ? '' : loading ? 'Loading more...' : !hasNextPage ? 'No more emails' : ''}
                                        </Typography>
                                    </Box>
                                ),
                            }}
                            itemContent={(index, thread) => (
                                <EmailCard
                                    key={thread.emailData.object.thread_id}
                                    thread={thread}
                                    isSelected={selectedThreadId === thread.emailData.object.thread_id}
                                    onClick={() => handleThreadSelect(thread)}
                                    selectedThreadId={selectedThreadId}
                                />
                            )}
                        />
                    </Box>
                ) : (
                    <Box sx={{ py: 2 }}>
                        <Typography textColor="text.tertiary" level="body-sm" sx={{ textAlign: 'center' }}>
                            {selectedThreadId ? '' : loading ? 'Loading...' : emptyStateMessage}
                        </Typography>
                    </Box>
                ))}
            {!isExpanded && <Divider />}
        </>
    )
}
