import { Checkbox } from '@mui/joy'
import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel'
import Input from '@mui/joy/Input'
import Link from '@mui/joy/Link'
import Typography from '@mui/joy/Typography'
import { signInSchema, SignInData } from '@numeo/types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Alert from '@mui/joy/Alert'
import CircularProgress from '@mui/joy/CircularProgress'
import { AxiosError } from 'axios'
import { PasswordField } from '../../components'
import { FormField } from 'components/common'
import { useAuth } from 'hooks/auth'

export default function LoginPage() {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const { login } = useAuth()

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<SignInData>({
        resolver: yupResolver(signInSchema),
        mode: 'onChange',
        defaultValues: {},
    })

    const onSubmit = async (formData: SignInData) => {
        setIsLoading(true)
        setError(null)

        try {
            await login(formData)
            navigate('/')
        } catch (err) {
            setError(err instanceof AxiosError ? err.response?.data?.message || 'Failed to sign in' : 'An unexpected error occurred')
        }

        setIsLoading(false)
    }

    return (
        <Box
            component="main"
            sx={{
                my: 'auto',
                py: 2,
                pb: 5,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: 400,
                maxWidth: '100%',
                mx: 'auto',
                borderRadius: 'sm',
                '& form': {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                },
                [`& .${formLabelClasses.asterisk}`]: {
                    visibility: 'hidden',
                },
            }}
        >
            <div>
                <Typography component="h1" fontSize="xl2" fontWeight="lg">
                    Sign in to your account
                </Typography>
                <Typography level="body-sm" sx={{ my: 1, mb: 3 }}>
                    Welcome back
                </Typography>
            </div>

            {error && (
                <Alert color="danger" variant="soft">
                    {error}
                </Alert>
            )}

            <form onSubmit={handleSubmit(onSubmit)}>
                <FormField error={errors.email}>
                    <FormLabel>Email</FormLabel>
                    <Input {...register('email')} type="email" name="email" autoComplete="email" />
                </FormField>

                <PasswordField registration={register('password')} formError={errors.password} autoComplete="current-password" />

                <FormLabel sx={{ display: 'flex', alignItems: 'center', gap: 2, cursor: 'pointer' }}>
                    <Checkbox {...register('persistent')} />
                    <Typography level="body-sm">Remember me</Typography>
                </FormLabel>

                <Button type="submit" fullWidth disabled={isLoading}>
                    {isLoading ? <CircularProgress size="sm" /> : 'Sign in'}
                </Button>
            </form>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Link fontSize="sm" href="/auth/sign-up" fontWeight="lg">
                    Don't have an account? Sign up
                </Link>
                <Link fontSize="sm" href="/auth/forgot-password" fontWeight="lg">
                    Forgot password?
                </Link>
            </Box>
        </Box>
    )
}
