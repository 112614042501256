import { SvgIconProps } from '@mui/joy'

export enum SourceType {
    Files = 'files',
    Text = 'text',
    Website = 'website',
    GoogleDrive = 'google-drive',
    OneDrive = 'onedrive',
    Dropbox = 'dropbox',
    Notion = 'notion',
    Intercom = 'intercom',
    Freshdesk = 'freshdesk',
    Zendesk = 'zendesk',
}

export interface AddKnowledgeBaseModalProps {
    open: boolean
    setOpen: (open: boolean) => void
    isGoogleDriveConnected: boolean
}

export interface SourceOption {
    name: string
    Icon: React.FC<SvgIconProps>
    comingSoon?: boolean
    sourceType: SourceType
}
