import axios from 'axios'
import React, { createContext, useEffect, useState } from 'react'
import { Application, ForgotPasswordData, ResetPasswordData, SignInData, SignUpData, User } from '@numeo/types'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AuthContextType } from './types'
import { AUTH_TOKEN } from 'setup/SetupAxios'

export const AuthContext = createContext<AuthContextType>({
    user: null,
    application: null,
    setUser: () => {},
    setApplication: () => {},
    isAuthenticated: false,
    login: () => Promise.resolve(),
    signup: () => Promise.resolve(),
    logout: () => {},
    forgotPassword: () => Promise.resolve(),
    resetPassword: () => Promise.resolve(),
    loginWithGoogle: () => Promise.resolve(),
})

export const useAuth = () => React.useContext(AuthContext)

interface AuthProviderProps {
    children: React.ReactNode
}

let token: string | null = localStorage.getItem(AUTH_TOKEN)!

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [application, setApplication] = useState<Application | null>(null)
    const [user, setUser] = useState<User | null>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    // Check token validity and get user data
    const verifyAuth = async () => {
        if (!token) {
            // Special case: When accessing the updater settings page with authBypass parameter,
            // we automatically set a test token. This allows the updater to work in demo mode
            // without requiring full authentication flow.
            const isTestingDemo = searchParams.get('authBypass') === 'true'

            if (isTestingDemo) {
                token = process.env.REACT_APP_TEST_USER_JWT!
                localStorage.setItem(AUTH_TOKEN, token)
            } else {
                setUser(null)
                setLoading(false)
                return
            }
        }

        try {
            const response = await axios.get('/auth/verify')
            setUser(response.data.user)
            if (response.data.application) {
                setApplication(response.data.application)
            }
        } catch (error) {
            console.error('Auth verification error:', error)
            logout()
        }

        setLoading(false)
    }

    // Check auth status when token changes
    useEffect(() => {
        verifyAuth()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Login function
    const login = async (formData: SignInData) => {
        const response = await axios.post('/auth/sign-in', formData)
        const { token: newToken, user: userData } = response.data
        token = newToken
        setUser(userData)
        localStorage.setItem(AUTH_TOKEN, token!)
    }

    // Signup function
    const signup = async (formData: SignUpData) => {
        const response = await axios.post('/auth/sign-up', formData)
        const { token: newToken, user: userData } = response.data
        token = newToken
        setUser(userData)
        localStorage.setItem(AUTH_TOKEN, token!)
    }

    // Forgot password function
    const forgotPassword = async (formData: ForgotPasswordData) => {
        await axios.post('/auth/forgot-password', formData)
    }

    // Reset password function
    const resetPassword = async (token: string, formData: ResetPasswordData) => {
        const response = await axios.post('/auth/reset-password', formData, {
            headers: { Authorization: `Bearer ${token}` },
        })
        const { token: newToken, user: userData } = response.data
        token = newToken
        setUser(userData)
        localStorage.setItem(AUTH_TOKEN, token!)
    }

    // Google sign-in function
    const loginWithGoogle = async (googleToken: string) => {
        const response = await axios.post('/auth/google', null, {
            headers: {
                Authorization: `Bearer ${googleToken}`,
            },
        })
        const { token: newToken, user: userData } = response.data
        token = newToken
        setUser(userData)
        localStorage.setItem(AUTH_TOKEN, token!)
    }

    // Logout function
    const logout = () => {
        localStorage.removeItem(AUTH_TOKEN)
        token = null
        setUser(null)
        navigate('/auth/sign-in')
    }

    if (loading) {
        return <div>Loading...</div>
    }

    return (
        <AuthContext.Provider
            value={{
                user,
                application,
                setUser,
                setApplication,
                isAuthenticated: Boolean(user),
                login,
                signup,
                logout,
                forgotPassword,
                resetPassword,
                loginWithGoogle,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider
