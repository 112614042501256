/**
 * Load a script by URL and return a promise that resolves when the script is loaded
 */

export const loadScript = (url: string): Promise<void> => {
    return new Promise((resolve, reject) => {
        // Check if script is already loaded
        if (document.querySelector(`script[src="${url}"]`)) {
            resolve()
            return
        }

        const script = document.createElement('script')
        script.src = url
        script.async = true
        script.defer = true

        script.onload = () => resolve()
        script.onerror = () => reject(new Error(`Failed to load script: ${url}`))

        document.head.appendChild(script)
    })
}

export default function decodeHtmlEntitiesInHtml(html: string): string {
    const parser = new DOMParser()
    const doc = parser.parseFromString(html, 'text/html')

    function decodeTextNodes(node) {
        node.childNodes.forEach((child) => {
            if (child.nodeType === Node.TEXT_NODE) {
                const element = document.createElement('div')
                element.innerHTML = child.nodeValue
                child.nodeValue = element.textContent || ''
            } else if (child.nodeType === Node.ELEMENT_NODE) {
                decodeTextNodes(child)
            }
        })
    }

    decodeTextNodes(doc.body)

    return doc.body.innerHTML
}
