import { useEffect, useState, useCallback } from 'react'
import Box from '@mui/joy/Box'
import Typography from '@mui/joy/Typography'
import { useSnackbar, ClickAwayListener } from '@mui/base'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { NylasEmailAccounts, NylasEmailAccountsSkeleton } from './components'
import { EmailAccount } from './api/NylasEmailClient/types'
import { NylasEmailClient } from './api'

type ToastColor = 'primary' | 'neutral' | 'danger' | 'success' | 'warning'

export default function NylasEmails() {
    const [accounts, setAccounts] = useState<EmailAccount[]>([])
    const [loading, setLoading] = useState(true)
    const [isAuthenticating, setIsAuthenticating] = useState(false)
    const [open, setOpen] = useState(false)
    const [toastMessage, setToastMessage] = useState('')
    const [toastColor, setToastColor] = useState<ToastColor>('neutral')
    const [searchParams] = useSearchParams()
    const location = useLocation()
    const navigate = useNavigate()

    const handleClose = () => {
        setOpen(false)
    }

    const { getRootProps, onClickAway } = useSnackbar({
        onClose: handleClose,
        open,
        autoHideDuration: 5000,
    })

    const showToast = (message: string, color: ToastColor = 'neutral') => {
        setToastMessage(message)
        setToastColor(color)
        setOpen(true)
    }

    const fetchAccounts = useCallback(async () => {
        try {
            setLoading(true)
            const data = await NylasEmailClient.getAccounts()
            setAccounts(data)
        } catch (error) {
            console.error('Error fetching accounts:', error)
            showToast('Failed to fetch email accounts', 'danger')
        } finally {
            setLoading(false)
            setIsAuthenticating(false)
        }
    }, [])

    const authenticateWithCode = useCallback(async (code: string) => {
        try {
            setIsAuthenticating(true)
            const response = await NylasEmailClient.exchangeToken(code)
            if (response.success) {
                await fetchAccounts()
                showToast('Email account connected successfully', 'success')
            } else {
                throw new Error('Failed to exchange token')
            }
        } catch (error) {
            console.error('Error exchanging token:', error)
            showToast('Email is already connected to an account', 'danger')
        } finally {
            setIsAuthenticating(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Handle OAuth callback from popup window
    useEffect(() => {
        const handleOAuthCallback = async (event: MessageEvent) => {
            if (event.data && event.data.type === 'NYLAS_AUTH_CALLBACK') {
                const { code } = event.data
                authenticateWithCode(code)
            }
        }

        window.addEventListener('message', handleOAuthCallback)
        return () => window.removeEventListener('message', handleOAuthCallback)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchAccounts])

    const handleAddAccount = async () => {
        try {
            setIsAuthenticating(true)
            const { authUrl } = await NylasEmailClient.getAuthUrl()

            // Open the Nylas auth URL in a popup window
            // const width = 600
            // const height = 800
            // const left = window.screen.width / 2 - width / 2
            // const top = window.screen.height / 2 - height / 2

            // FIXME: Open the Nylas auth URL in a popup window not redirecting back, so having to destroy current window and redirect to authUrl
            // window.open(authUrl, 'Nylas Auth', `width=${width},height=${height},top=${top},left=${left}`)
            window.location.href = authUrl
        } catch (error) {
            console.error('Error initiating auth:', error)
            showToast('Failed to start authentication', 'danger')
            setIsAuthenticating(false)
        }
    }

    const handleDisconnect = async (account: EmailAccount) => {
        try {
            await NylasEmailClient.disconnect(account.grantId)
            showToast('Email account disconnected successfully', 'success')
            await fetchAccounts()
        } catch (error) {
            showToast(error instanceof Error ? error.message : 'Failed to disconnect email account', 'danger')
        }
    }

    useEffect(() => {
        fetchAccounts()
    }, [fetchAccounts])

    useEffect(() => {
        const code = searchParams.get('code')

        if (code) {
            authenticateWithCode(code)
            navigate(location.pathname, { replace: true })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams])

    return (
        <Box sx={{ p: 2 }}>
            <Typography level="h1" sx={{ mb: 2 }}>
                Email Accounts
            </Typography>

            {loading ? (
                <NylasEmailAccountsSkeleton />
            ) : (
                <NylasEmailAccounts accounts={accounts} onAddAccount={handleAddAccount} onDisconnect={handleDisconnect} isLoading={isAuthenticating} />
            )}

            {open ? (
                <ClickAwayListener onClickAway={onClickAway}>
                    <div
                        {...getRootProps()}
                        style={{
                            position: 'fixed',
                            bottom: 16,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            backgroundColor: toastColor === 'danger' ? '#d32f2f' : toastColor === 'success' ? '#2e7d32' : '#1976d2',
                            color: 'white',
                            padding: '6px 16px',
                            borderRadius: 4,
                            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
                            zIndex: 1400,
                        }}
                    >
                        {toastMessage}
                        <button
                            onClick={handleClose}
                            style={{
                                marginLeft: 8,
                                background: 'none',
                                border: 'none',
                                color: 'white',
                                cursor: 'pointer',
                                padding: '4px',
                            }}
                        >
                            ✕
                        </button>
                    </div>
                </ClickAwayListener>
            ) : null}
        </Box>
    )
}
