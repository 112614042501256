import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import AppRoutes from './pages/routes'
import ErrorBoundary from 'components/error/ErrorBoundary'

dayjs.extend(duration)
dayjs.extend(relativeTime)

export default function App() {
    return (
        <ErrorBoundary>
            <AppRoutes />
        </ErrorBoundary>
    )
}
