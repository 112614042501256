import Box from '@mui/joy/Box'
import Breadcrumbs from '@mui/joy/Breadcrumbs'
import CssBaseline from '@mui/joy/CssBaseline'
import Link from '@mui/joy/Link'
import Typography from '@mui/joy/Typography'
import { CssVarsProvider } from '@mui/joy/styles'

import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import Header from './components/Header'
import Sidebar from './components/Sidebar'
import { Children } from 'types'

export default function MasterLayout({ children }: Children) {
    const pathname = window.location.pathname.split('/')

    return (
        <CssVarsProvider disableTransitionOnChange>
            <CssBaseline />
            <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
                <Header />
                <Sidebar />
                <Box
                    component="main"
                    className="MainContent"
                    sx={{
                        px: { xs: 2, md: 6 },
                        pt: {
                            xs: 'calc(12px + var(--Header-height))',
                            sm: 'calc(12px + var(--Header-height))',
                            md: 3,
                        },
                        pb: { xs: 2, sm: 2, md: 3 },
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 0,
                        height: 'max-content',
                        gap: 1,
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Breadcrumbs size="sm" aria-label="breadcrumbs" separator={<ChevronRightRoundedIcon fontSize="small" />} sx={{ pl: 0 }}>
                            <Link underline="none" color="neutral" href="/" aria-label="Home">
                                <HomeRoundedIcon />
                            </Link>
                            {pathname.map((path, index) => {
                                if (path === '') return null
                                return (
                                    <Link key={index} underline="none" color="neutral" href={`${window.location.pathname.split(path)[0] + path}`}>
                                        <Typography color="primary" fontWeight={500} fontSize={12} sx={{ textTransform: 'capitalize' }}>
                                            {path}
                                        </Typography>
                                    </Link>
                                )
                            })}
                        </Breadcrumbs>
                    </Box>

                    <Typography level="h2" sx={{ textTransform: 'capitalize', pl: 1 }}>
                        {pathname ? pathname.pop() : 'Dashboard'}
                    </Typography>

                    {children}
                </Box>
            </Box>
        </CssVarsProvider>
    )
}
