import { Telegram, Check } from '@mui/icons-material'
import { SetStateAction, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import Chip, { chipClasses } from '@mui/joy/Chip'
import Tabs from '@mui/joy/Tabs'
import {
    Card,
    Checkbox,
    Slider,
    Select,
    Stack,
    styled,
    TabPanel,
    Typography,
    Option,
    TabList,
    tabClasses,
    Tab,
    FormLabel,
    FormControl,
    Divider,
    Input,
} from '@mui/joy'
import { FormControlLabel } from '@mui/material'
import { statesList } from './const'
import { getEmailSettings, saveEmailSettings } from './api/EmailSettings'
import { useSnackbar, ClickAwayListener } from '@mui/base'
import { TelegramClient } from './api'
import { useAuth } from 'hooks/auth'

interface IStateInterface {
    data: { value: string; label: string }[]
    initialData: string[]
    setStateData: React.Dispatch<SetStateAction<string[]>>
}

function StateSelector({ data, initialData, setStateData }: IStateInterface) {
    const handleChange = (event: React.SyntheticEvent | null, newValue: Array<string> | null) => {
        setStateData(newValue as string[])
    }

    return (
        <Box sx={{ minWidth: 220 }}>
            <Select multiple placeholder="Choose states" onChange={handleChange} value={initialData}>
                {data.map(({ value, label }: { value: string; label: string }) => (
                    <Option key={value} value={value}>
                        {label}
                    </Option>
                ))}
            </Select>
        </Box>
    )
}

const ConnectButton = styled(Button)(() => ({
    bgcolor: '#0088cc',
    '&:hover': {
        bgcolor: '#0077b3',
    },
    display: 'flex',
    alignItems: 'center',
}))

const SettingsCard = styled(Card)(({ theme }) => ({
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
}))

export default function SettingsPage() {
    const [telegramId, setTelegramId] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)

    const { application, setApplication, setUser } = useAuth()
    const [open, setOpen] = useState(false)
    const [toastMessage, setToastMessage] = useState('')
    const [toastColor, setToastColor] = useState<'success' | 'warning'>('warning')

    const handleClose = () => {
        setOpen(false)
    }

    const { getRootProps, onClickAway } = useSnackbar({
        onClose: handleClose,
        open,
        autoHideDuration: 3000,
    })
    const [exclusionStatesList, setExclusionStatesList] = useState<string[]>(application?.temp?.emailSettings?.state || [])
    const [price, setPrice] = useState<number[]>([application?.temp?.emailSettings?.price?.min || 0, application?.temp?.emailSettings?.price?.max || 10])
    const [isMountainAreaIncluded, setIsMountainAreaIncluded] = useState(application?.temp?.emailSettings?.mountainArea || false)
    const [loadingSetting, setLoadingSetting] = useState(false)

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsMountainAreaIncluded(event.target.checked)
    }

    const showToast = (message: string, color: 'success' | 'warning') => {
        setToastMessage(message)
        setToastColor(color)
        setOpen(true)
    }

    const location = useLocation()
    const navigate = useNavigate()

    const connectTelegram = async (token: string) => {
        try {
            setLoading(true)
            const decodedString = atob(token)
            const telegramId = JSON.parse(decodedString).id

            const response = await TelegramClient.connectTelegram(telegramId)
            if (response.success) {
                setApplication(response.data)
                setTelegramId(response.data.connectors.messageners.telegram.id)
                showToast('Telegram connected successfully!', 'success')
            }
        } catch (error) {
            console.error('Failed to connect Telegram:', error)
            showToast('Failed to connect Telegram', 'warning')
        } finally {
            setLoading(false)
        }
    }

    const onConnect = () => {
        window.location.href = `https://oauth.telegram.org/auth?bot_id=${process.env.REACT_APP_TELEGRAM_BOT_ID}&origin=${process.env.REACT_APP_BASE_URL}/settings`
    }
    const disconnectTelegram = async () => {
        try {
            setLoading(true)
            const response = await TelegramClient.disconnectTelegram()
            if (response.success) {
                setApplication(response.data)
                setTelegramId(null)
                setUser((prev) => ({ ...prev, ...response.user }))
                showToast('Telegram disconnected successfully!', 'success')
            }
        } catch (error) {
            console.error('Failed to disconnect Telegram:', error)
            showToast('Failed to disconnect Telegram', 'warning')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        const hash = window.location.hash.substring(1)
        const params = new URLSearchParams(hash)
        const telegramAuthToken = params.get('tgAuthResult')

        if (telegramAuthToken) {
            connectTelegram(telegramAuthToken)
            navigate(location.pathname)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, navigate])

    const dispatchEmailSettings = async () => {
        const emailSettings = {
            state: exclusionStatesList,
            mountainArea: isMountainAreaIncluded,
            price: {
                min: price[0],
                max: price[1],
            },
        }
        try {
            setLoadingSetting(true)
            const response = await saveEmailSettings(emailSettings)
            if (response) {
                console.log(response, 'response')
            }
        } catch (error) {
            console.error('Failed to disconnect Telegram:', error)
        } finally {
            setLoadingSetting(false)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getEmailSettings()
                if (data.status === 200) {
                    const { state, mountainArea, price } = data.data
                    setExclusionStatesList(state)
                    setIsMountainAreaIncluded(mountainArea)
                    setPrice([price.min, price.max])
                }
            } catch (error) {
                console.error('Failed to fetch email settings:', error)
            }
        }

        fetchData()
    }, [])

    return (
        <Stack spacing={4}>
            <Box sx={{ flex: 1, width: '100%', mx: 'auto' }}>
                <Tabs defaultValue={0} sx={{ bgcolor: 'transparent' }}>
                    <Box
                        sx={{
                            '--_shadow-height': '16px',
                            height: 0,
                            position: 'sticky',
                            top: 'calc(48px - var(--main-paddingTop, 0px) + var(--Header-height, 0px) - (var(--_shadow-height) / 2))',
                            zIndex: 1,
                            '&::before': {
                                content: '""',
                                display: 'block',
                                position: 'relative',
                                zIndex: 1,
                                height: 'var(--_shadow-height)',
                                background: 'radial-gradient(closest-side, rgba(0 0 0 / 0.12), transparent 100%)',
                            },
                        }}
                    />
                    <TabList
                        sticky="top"
                        variant="plain"
                        sx={(theme) => ({
                            '--Chip-minHeight': '20px',
                            '--ListItem-minHeight': '48px',
                            top: 'calc(-1 * (var(--main-paddingTop, 0px) - var(--Header-height, 0px)))',
                            zIndex: 10,
                            width: '100%',
                            overflow: 'auto hidden',
                            alignSelf: 'flex-start',
                            scrollSnapType: 'inline',
                            '&::after': {
                                pointerEvents: 'none',
                                display: { xs: 'block', sm: 'none' },
                                content: '""',
                                position: 'sticky',
                                top: 0,
                                width: 40,
                                flex: 'none',
                                zIndex: 1,
                                right: 0,
                                borderBottom: '1px solid transparent',
                                background: `linear-gradient(to left, ${theme.vars.palette.background.body}, rgb(0 0 0 / 0))`,
                                backgroundClip: 'content-box',
                            },
                            '&::-webkit-scrollbar': {
                                width: 0,
                                display: 'none',
                            },
                            [`& .${tabClasses.root}`]: {
                                '--focus-outline-offset': '-2px',
                                '&:first-of-type': {
                                    ml: 'calc(-1 * var(--ListItem-paddingX))',
                                },
                                scrollSnapAlign: 'start',
                                bgcolor: 'transparent',
                                flex: 'none',
                                '&:hover': {
                                    bgcolor: 'transparent',
                                },
                                [`&.${tabClasses.selected}`]: {
                                    color: 'primary.plainColor',
                                    bgcolor: 'transparent',
                                    [`& .${chipClasses.root}`]: theme.variants.solid.primary,
                                },
                            },
                        })}
                    >
                        <Tab indicatorInset value={0}>
                            Account Details
                        </Tab>
                        <Tab indicatorInset value={1}>
                            Email Settings
                        </Tab>
                    </TabList>
                    <TabPanel value={0}>
                        <Box
                            sx={{
                                pt: 3,
                                pb: 10,
                                display: 'grid',
                                gridTemplateColumns: {
                                    xs: '100%',
                                    sm: 'minmax(120px, 30%) 1fr',
                                    lg: '280px 1fr minmax(120px, 208px)',
                                },
                                columnGap: { xs: 2, sm: 3, md: 4 },
                                rowGap: { xs: 2, sm: 2.5 },
                                '& > hr': {
                                    gridColumn: '1/-1',
                                },
                            }}
                        >
                            <FormLabel sx={{ display: { xs: 'none', sm: 'block' } }}>Account Login</FormLabel>
                            <Box sx={{ display: { xs: 'contents', sm: 'flex' }, gap: 2 }}>
                                <FormControl sx={{ flex: 1 }}>
                                    <FormLabel>Email Address</FormLabel>
                                    <Input placeholder="Email" type="email" />
                                </FormControl>
                                <FormControl sx={{ flex: 1 }}></FormControl>
                            </Box>
                            <Divider role="presentation" />
                            <FormLabel sx={{ display: { xs: 'none', sm: 'block' } }}>Update Password</FormLabel>
                            <Box sx={{ display: { xs: 'contents', sm: 'flex' }, gap: 2 }}>
                                <FormControl sx={{ flex: 1 }} disabled>
                                    <FormLabel>New Password</FormLabel>
                                    <Input placeholder="Password" type="password" />
                                </FormControl>
                                <FormControl sx={{ flex: 1 }} disabled>
                                    <FormLabel>Confirm Password</FormLabel>
                                    <Input placeholder="Confirm" type="password" />
                                </FormControl>
                            </Box>
                            <Box
                                sx={{
                                    gridColumn: '1/-1',
                                    justifySelf: 'flex-start',
                                    display: 'flex',
                                    gap: 1,
                                }}
                            >
                                <Button variant="outlined" color="neutral" size="sm">
                                    Cancel
                                </Button>
                                <Button size="sm">Save</Button>
                            </Box>
                        </Box>
                    </TabPanel>
                    <TabPanel value={1}>
                        <Box
                            sx={{
                                pt: 3,
                                pb: 10,
                                display: 'grid',
                                gridTemplateColumns: {
                                    xs: '100%',
                                    sm: 'minmax(120px, 30%) 1fr',
                                    lg: '280px 1fr minmax(120px, 208px)',
                                },
                                columnGap: { xs: 2, sm: 3, md: 4 },
                                rowGap: { xs: 2, sm: 2.5 },
                                '& > hr': {
                                    gridColumn: '1/-1',
                                },
                            }}
                        >
                            <FormLabel sx={{ display: { xs: 'none', sm: 'block' } }}>State information</FormLabel>
                            <Box sx={{ display: { xs: 'contents', sm: 'flex' }, gap: 2 }}>
                                <FormControl sx={{ flex: 1 }}>
                                    <FormLabel>State Exclusion List</FormLabel>
                                    <StateSelector initialData={exclusionStatesList} setStateData={setExclusionStatesList} data={statesList} />
                                </FormControl>
                            </Box>
                            <Divider role="presentation" />
                            <FormLabel sx={{ display: { xs: 'none', sm: 'block' } }}>Mountain Area</FormLabel>
                            <Box sx={{ display: { xs: 'contents', sm: 'flex' }, gap: 2 }}>
                                <FormControlLabel
                                    control={<Checkbox defaultChecked={isMountainAreaIncluded} onChange={handleCheckboxChange} />}
                                    label="Is Mountain Area Included"
                                    sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                                />
                            </Box>
                            <Divider role="presentation" />
                            <FormLabel sx={{ display: { xs: 'none', sm: 'block' } }}>Select Price Per mile (USD)</FormLabel>
                            <Box sx={{ display: { xs: 'contents', sm: 'flex', width: '100%' }, gap: 2 }}>
                                <FormControl>
                                    <FormLabel>Price per Mile (USD)</FormLabel>
                                    <Slider
                                        sx={{ minWidth: 350 }}
                                        value={[price[0], price[1]]}
                                        onChange={(_, value) => setPrice(value as SetStateAction<number[]>)}
                                        min={1}
                                        max={20}
                                        step={0.1}
                                        valueLabelDisplay="auto"
                                        marks={[
                                            { value: 1, label: '$1' },
                                            { value: 10, label: '$10' },
                                            { value: 20, label: '$20' },
                                        ]}
                                        disableSwap
                                    />
                                </FormControl>
                            </Box>
                            <Box
                                sx={{
                                    gridColumn: '1/-1',
                                    justifySelf: 'flex-start',
                                    display: 'flex',
                                    gap: 1,
                                }}
                            >
                                <Button variant="outlined" color="neutral" size="sm">
                                    Cancel
                                </Button>
                                <Button size="sm" onClick={dispatchEmailSettings} loading={loadingSetting}>
                                    Save
                                </Button>
                            </Box>
                        </Box>
                    </TabPanel>
                </Tabs>
            </Box>
            <SettingsCard variant="outlined">
                <Stack spacing={3}>
                    <Box>
                        <Typography level="h4" sx={{ mb: 1 }}>
                            Notifications
                        </Typography>
                        <Typography level="body-sm" color="neutral" sx={{ mb: 2 }}>
                            Get instant notifications about your emails directly on Telegram
                        </Typography>

                        {application?.connectors?.messageners?.telegram?.id ? (
                            <Stack spacing={2}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                    <Typography level="title-md">Telegram ID: {telegramId}</Typography>
                                    <Chip size="sm" variant="soft" color="success" startDecorator={<Check />}>
                                        Connected
                                    </Chip>
                                </Box>
                                <Button variant="outlined" color="danger" onClick={disconnectTelegram} loading={loading} sx={{ alignSelf: 'flex-start' }}>
                                    Disconnect Telegram
                                </Button>
                            </Stack>
                        ) : (
                            <ConnectButton variant="solid" color="primary" startDecorator={<Telegram />} onClick={onConnect} loading={loading}>
                                Connect Telegram
                            </ConnectButton>
                        )}
                    </Box>
                </Stack>
            </SettingsCard>
            {open && (
                <ClickAwayListener onClickAway={onClickAway}>
                    <div
                        {...getRootProps()}
                        style={{
                            position: 'fixed',
                            bottom: 16,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            backgroundColor: toastColor === 'warning' ? '#ffa726' : '#2e7d32',
                            color: 'white',
                            padding: '6px 16px',
                            borderRadius: 4,
                            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
                            zIndex: 1400,
                        }}
                    >
                        {toastMessage}
                        <button
                            onClick={handleClose}
                            style={{
                                marginLeft: 8,
                                background: 'none',
                                border: 'none',
                                color: 'white',
                                cursor: 'pointer',
                                padding: '4px',
                            }}
                        >
                            ✕
                        </button>
                    </div>
                </ClickAwayListener>
            )}
        </Stack>
    )
}
