import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import SupportRoundedIcon from '@mui/icons-material/SupportRounded'
import Avatar from '@mui/joy/Avatar'
import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import Card from '@mui/joy/Card'
import Chip from '@mui/joy/Chip'
import Divider from '@mui/joy/Divider'
import GlobalStyles from '@mui/joy/GlobalStyles'
import IconButton from '@mui/joy/IconButton'
import Input from '@mui/joy/Input'
import LinearProgress from '@mui/joy/LinearProgress'
import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton'
import ListItemContent from '@mui/joy/ListItemContent'
import Sheet from '@mui/joy/Sheet'
import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography'
import * as React from 'react'
import { styled } from '@mui/joy/styles'

import { HeadsetMic, LocalPolice, People, Sync } from '@mui/icons-material'
import { useNavigate, useLocation } from 'react-router-dom'
import { closeSidebar } from '../../utils'
import ColorSchemeToggle from '../../components/ColorSchemeToggle'
import { useAuth } from 'hooks/auth'

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
    '&.Mui-disabled': {
        opacity: 0.5,
        backgroundColor: theme.vars.palette.background.level1,
        '&:hover': {
            backgroundColor: theme.vars.palette.background.level1,
        },
    },
}))

function Toggler({
    defaultExpanded = false,
    renderToggle,
    children,
}: {
    defaultExpanded?: boolean
    children: React.ReactNode
    renderToggle: (params: { open: boolean; setOpen: React.Dispatch<React.SetStateAction<boolean>> }) => React.ReactNode
}) {
    const [open, setOpen] = React.useState(defaultExpanded)
    return (
        <React.Fragment>
            {renderToggle({ open, setOpen })}
            <Box
                sx={[
                    {
                        display: 'grid',
                        transition: '0.2s ease',
                        '& > *': {
                            overflow: 'hidden',
                        },
                    },
                    open ? { gridTemplateRows: '1fr' } : { gridTemplateRows: '0fr' },
                ]}
            >
                {children}
            </Box>
        </React.Fragment>
    )
}

export default function Sidebar() {
    const { user, logout } = useAuth()
    const navigate = useNavigate()
    const location = useLocation()

    // Simple check if we're in the dispatcher section
    const isDispatcherActive = location.pathname.startsWith('/dispatcher')

    return (
        <Sheet
            className="Sidebar"
            sx={{
                position: { xs: 'fixed', md: 'sticky' },
                transform: {
                    xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
                    md: 'none',
                },
                transition: 'transform 0.4s, width 0.4s',
                zIndex: 10,
                height: '100dvh',
                width: 'var(--Sidebar-width)',
                top: 0,
                p: 2,
                flexShrink: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRight: '1px solid',
                borderColor: 'divider',
            }}
        >
            <GlobalStyles
                styles={(theme) => ({
                    ':root': {
                        '--Sidebar-width': '280px',
                        [theme.breakpoints.up('lg')]: {
                            '--Sidebar-width': '310px',
                        },
                    },
                })}
            />
            <Box
                className="Sidebar-overlay"
                sx={{
                    position: 'fixed',
                    zIndex: 9998,
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    opacity: 'var(--SideNavigation-slideIn)',
                    backgroundColor: 'var(--joy-palette-background-backdrop)',
                    transition: 'opacity 0.4s',
                    transform: {
                        xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
                        lg: 'translateX(-100%)',
                    },
                }}
                onClick={() => closeSidebar()}
            />
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <IconButton variant="soft" color="primary" size="sm" onClick={() => navigate('/dispatcher')}>
                    <img alt="logo" src="/logo.svg" width="28" height="28" />
                </IconButton>
                <Typography level="title-lg">Numeo.ai</Typography>
                <ColorSchemeToggle sx={{ ml: 'auto' }} />
            </Box>
            <Input size="sm" startDecorator={<SearchRoundedIcon />} placeholder="Search" />
            <Box
                sx={{
                    minHeight: 0,
                    overflow: 'hidden auto',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    [`& .${listItemButtonClasses.root}`]: {
                        gap: 1.5,
                    },
                }}
            >
                <List
                    size="sm"
                    sx={{
                        gap: 1,
                        '--List-nestedInsetStart': '30px',
                        '--ListItem-radius': (theme) => theme.vars.radius.sm,
                    }}
                >
                    <ListItem nested>
                        <Typography level="title-sm" color="primary" sx={{ mb: 0.5 }}>
                            Agents
                        </Typography>
                        <Toggler
                            defaultExpanded={isDispatcherActive}
                            renderToggle={({ open, setOpen }) => (
                                <ListItemButton
                                    onClick={() => {
                                        navigate('dispatcher')
                                        setOpen(!open)
                                    }}
                                >
                                    <HeadsetMic />
                                    <ListItemContent>
                                        <Typography level="title-sm">Dispatcher Agent</Typography>
                                    </ListItemContent>
                                    <KeyboardArrowDownIcon sx={{ transform: open ? 'rotate(180deg)' : 'none' }} />
                                </ListItemButton>
                            )}
                        >
                            <List sx={{ gap: 0.5 }}>
                                <ListItem sx={{ mt: 0.5 }}>
                                    <ListItemButton onClick={() => navigate('dispatcher')}>Dashboard</ListItemButton>
                                </ListItem>
                                <ListItem sx={{ mt: 0.5 }}>
                                    <ListItemButton onClick={() => navigate('dispatcher/emails')}>Emails</ListItemButton>
                                </ListItem>
                                {/* <ListItem>
                                   <ListItemButton>Order history</ListItemButton>
                               </ListItem> */}
                                <ListItem>
                                    <ListItemButton onClick={() => navigate('dispatcher/settings')}>Setting</ListItemButton>
                                </ListItem>
                            </List>
                        </Toggler>
                    </ListItem>
                    <ListItem nested>
                        <Toggler
                            renderToggle={() => (
                                <StyledListItemButton disabled>
                                    <Sync />
                                    <ListItemContent>
                                        <Typography level="title-sm">Updater Agents</Typography>
                                    </ListItemContent>
                                    <Chip
                                        size="sm"
                                        variant="soft"
                                        color="warning"
                                        sx={{
                                            '--Chip-minHeight': '20px',
                                            '--Chip-paddingInline': '8px',
                                            fontSize: '11px',
                                        }}
                                    >
                                        Coming Soon
                                    </Chip>
                                </StyledListItemButton>
                            )}
                        >
                            <List sx={{ gap: 0.5 }}>
                                <ListItem sx={{ mt: 0.5 }}>
                                    <StyledListItemButton disabled>Dashboard</StyledListItemButton>
                                </ListItem>
                                <ListItem sx={{ mt: 0.5 }}>
                                    <StyledListItemButton disabled>Call history</StyledListItemButton>
                                </ListItem>
                                <ListItem>
                                    <StyledListItemButton disabled>Settings</StyledListItemButton>
                                </ListItem>
                            </List>
                        </Toggler>
                    </ListItem>
                    <ListItem nested>
                        <StyledListItemButton disabled>
                            <LocalPolice />
                            <ListItemContent>
                                <Typography level="title-sm">Compliance Agent</Typography>
                            </ListItemContent>
                            <Chip
                                size="sm"
                                variant="soft"
                                color="warning"
                                sx={{
                                    '--Chip-minHeight': '20px',
                                    '--Chip-paddingInline': '8px',
                                    fontSize: '11px',
                                }}
                            >
                                Coming Soon
                            </Chip>
                        </StyledListItemButton>
                    </ListItem>
                    <ListItem nested>
                        <Toggler
                            renderToggle={() => (
                                <StyledListItemButton disabled>
                                    <People />
                                    <ListItemContent>
                                        <Typography level="title-sm">HR Agent</Typography>
                                    </ListItemContent>
                                    <Chip
                                        size="sm"
                                        variant="soft"
                                        color="warning"
                                        sx={{
                                            '--Chip-minHeight': '20px',
                                            '--Chip-paddingInline': '8px',
                                            fontSize: '11px',
                                        }}
                                    >
                                        Coming Soon
                                    </Chip>
                                </StyledListItemButton>
                            )}
                        >
                            <List sx={{ gap: 0.5 }}>
                                <ListItem sx={{ mt: 0.5 }}>
                                    <StyledListItemButton disabled>Dashboard</StyledListItemButton>
                                </ListItem>
                                <ListItem>
                                    <StyledListItemButton disabled>Candidates</StyledListItemButton>
                                </ListItem>
                                <ListItem>
                                    <StyledListItemButton disabled>Settings</StyledListItemButton>
                                </ListItem>
                            </List>
                        </Toggler>
                    </ListItem>
                </List>
                <List
                    size="sm"
                    sx={{
                        mt: 'auto',
                        flexGrow: 0,
                        '--ListItem-radius': (theme) => theme.vars.radius.sm,
                        '--List-gap': '8px',
                        mb: 2,
                    }}
                >
                    <ListItem>
                        <ListItemButton>
                            <SupportRoundedIcon />
                            Support
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton onClick={() => navigate('settings')}>
                            <SettingsRoundedIcon />
                            Settings
                        </ListItemButton>
                    </ListItem>
                </List>
                <Card invertedColors variant="soft" color="warning" size="sm" sx={{ boxShadow: 'none' }}>
                    <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography level="title-sm">Used credits</Typography>
                        <IconButton size="sm">
                            <CloseRoundedIcon />
                        </IconButton>
                    </Stack>
                    <Typography level="body-xs">Your team has used 80% of your available credits. Need more?</Typography>
                    <LinearProgress variant="outlined" value={80} determinate sx={{ my: 1 }} />
                    <Button size="sm" variant="solid">
                        Upgrade plan
                    </Button>
                </Card>
            </Box>
            <Divider />
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Avatar variant="outlined" size="sm" src="https://instavibe.uz/image/05ce2a291be930c6653c2e7cd8f345a0.png" />
                <Box sx={{ minWidth: 0, flex: 1 }}>
                    <Typography level="title-sm">Akmal P.</Typography>
                    <Typography level="body-xs">{user?.email}</Typography>
                </Box>
                <IconButton onClick={logout} size="sm" variant="plain" color="neutral">
                    <LogoutRoundedIcon />
                </IconButton>
            </Box>
        </Sheet>
    )
}
