import { Sheet, Divider, Box } from '@mui/joy'
import { EmailDetails, EmailHeader, EmailAttachments } from './components'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import DOMPurify from 'dompurify'
import { HTML_TEMPLATE, SANITIZE_OPTIONS } from './const'
import { FetchedEmail } from '../../../../api/EmailThreadClient'

interface EmailCardProps {
    email: FetchedEmail
    isReply?: boolean
    isFromMainSender: boolean
    handleParse: () => void
    loading: boolean
    isParsed?: boolean
}

const EmailCard: React.FC<EmailCardProps> = ({ email, isReply, isFromMainSender, handleParse, loading, isParsed }) => {
    const emailData = email?.emailData?.object
    const [showDetails, setShowDetails] = useState(false)
    const iframeRef = useRef<HTMLIFrameElement>(null)

    const handleToggleDetails = useCallback(() => {
        setShowDetails((prev) => !prev)
    }, [])

    const setupIframeContent = useCallback((iframe: HTMLIFrameElement, content: string) => {
        const doc = iframe.contentDocument || iframe.contentWindow?.document
        if (!doc) return

        // FIXME: sometimes DOMPurify becoming undefined
        const cleanContent = DOMPurify?.sanitize(content, SANITIZE_OPTIONS)

        doc.open()
        doc.write(HTML_TEMPLATE(cleanContent))
        doc.close()

        // Make external links open in new tab
        doc.querySelectorAll('a').forEach((link) => {
            if (link.host !== window.location.host) {
                link.setAttribute('target', '_blank')
                link.setAttribute('rel', 'noopener noreferrer')
            }
        })

        return doc.body
    }, [])

    useEffect(() => {
        if (!iframeRef.current || !emailData?.body) return

        const iframe = iframeRef.current
        const body = setupIframeContent(iframe, emailData.body)

        if (!body) return

        // Handle iframe resizing
        const resizeObserver = new ResizeObserver(() => {
            iframe.style.height = `${body.scrollHeight}px`
        })

        resizeObserver.observe(body)

        return () => resizeObserver.disconnect()
    }, [emailData?.body, setupIframeContent])

    if (!emailData) return null

    return (
        <Sheet
            variant="outlined"
            sx={{
                borderRadius: 'sm',
                p: 2,
                mb: 2,
                ml: isReply ? (!isFromMainSender ? 4 : 'auto') : 0,
                mr: isReply ? (!isFromMainSender ? 0 : 4) : 0,
                width: isReply ? '85%' : '100%',
            }}
        >
            <EmailHeader
                emailData={emailData}
                isReply={isReply}
                onToggleDetails={handleToggleDetails}
                showDetails={showDetails}
                handleParse={handleParse}
                isParsed={isParsed}
                loading={loading}
            />

            <Divider sx={{ mt: 2 }} />

            {(!isReply || showDetails) && (
                <>
                    <EmailDetails emailData={emailData} />
                    <Divider />
                </>
            )}

            <Box sx={{ mt: 2, mb: 2 }}>
                <iframe
                    ref={iframeRef}
                    style={{
                        width: '100%',
                        border: 'none',
                        overflow: 'hidden',
                        minHeight: 'max-content',
                    }}
                    title={`Email: ${emailData.subject || 'No subject'}`}
                    sandbox="allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                />
            </Box>

            <EmailAttachments attachments={emailData.attachments || []} />
        </Sheet>
    )
}

export default EmailCard
