import FormControl from '@mui/joy/FormControl'
import Typography from '@mui/joy/Typography'
import React from 'react'
import { FieldError } from 'react-hook-form'

interface FormFieldProps {
    error?: FieldError
    children: React.ReactNode
}

export function FormField({ error, children }: FormFieldProps) {
    return (
        <FormControl error={Boolean(error)}>
            {children}
            {error && (
                <Typography level="body-xs" color="danger">
                    {error.message}
                </Typography>
            )}
        </FormControl>
    )
}
