export const BASE_STYLES = `
    body {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
        font-size: 14px;
        line-height: 1.5;
        color: #1C2025;
        margin: 0;
        padding: 0;
    }
    img {
        max-width: 100%;
        height: auto;
    }
    a {
        color: #0B6BCB;
        text-decoration: none;
    }
    blockquote {
        border-left: 3px solid #CDD7E1;
        margin: 0;
        padding-left: 1em;
        color: #4B5259;
    }
`

export const HTML_TEMPLATE = (content: string) => `
<!DOCTYPE html>
<html>
    <head>
        <meta charset="utf-8">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <style>${BASE_STYLES}</style>
    </head>
    <body>${content}</body>
</html>
`

export const SANITIZE_OPTIONS = {
    FORBID_TAGS: ['script', 'iframe', 'object', 'embed'],
    ADD_ATTR: ['target'], // Allow target attribute for links
    ADD_TAGS: ['iframe'], // Allow specific iframes if needed
    SANITIZE_DOM: true,
    ALLOW_DATA_ATTR: false,
}
