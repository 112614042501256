import { array, object, string } from 'yup'

// Schema for adding Google Drive documents
export const addGoogleDocsSchema = object({
    email: string().email('Invalid email format').required('Email is required'),
    documents: array()
        .of(
            object({
                id: string().required('Document ID is required'),
                name: string().required('Document name is required'),
            })
        )
        .min(1, 'At least one document is required')
        .required('Documents array is required'),
})

// Schema for removing a Google Drive document
export const removeGoogleDocSchema = object({
    id: string()
        .required('Document ID is required')
        .matches(/^[0-9a-fA-F]{24}$/, 'Invalid document ID format'),
})
