import Box from '@mui/joy/Box'
import { useState, useCallback } from 'react'
import { useMediaQuery } from '@mui/material'
import { Mails, EmailContent } from './components'
import { EmailThreadClient, FetchedEmail } from './api/EmailThreadClient'
import { CircularProgress } from '@mui/joy'
import { ApplicationOrder } from '@numeo/types'

export default function DispatcherEmailsPage() {
    const [selectedThreadId, setSelectedThreadId] = useState<string | null>(null)
    const [selectedEmail, setSelectedEmail] = useState<FetchedEmail | null>(null)
    const [replies, setReplies] = useState<FetchedEmail[]>([])
    const [isLoadingReplies, setIsLoadingReplies] = useState(false)
    const [order, setOrder] = useState<ApplicationOrder | null>(null)

    const isMobile = useMediaQuery('(max-width:800px)')

    const handleEmailSelect = useCallback(async (thread: FetchedEmail) => {
        try {
            const threadId = thread.emailData.object.thread_id
            setIsLoadingReplies(true)
            setSelectedEmail(thread)
            setSelectedThreadId(threadId)

            const fetchedReplies = await EmailThreadClient.getThreadRepliesAndDetails(threadId)
            setReplies(fetchedReplies.threads?.filter((reply) => reply?.emailData?.object) ?? [])
            setOrder(fetchedReplies.order)
        } catch (error) {
            console.error('Failed to fetch thread replies:', error)
        } finally {
            setIsLoadingReplies(false)
        }
    }, [])

    const handleBack = () => {
        setSelectedThreadId(null)
        setSelectedEmail(null)
        setReplies([])
        setOrder(null)
    }

    return (
        <Box sx={{ display: 'flex', minHeight: '100%' }}>
            {(!isMobile || !selectedThreadId) && (
                <Box
                    sx={{
                        width: selectedThreadId ? 65 : 400,
                        borderRight: '1px solid',
                        borderColor: 'divider',
                    }}
                >
                    <Mails selectedThreadId={selectedThreadId} onThreadSelect={handleEmailSelect} />
                </Box>
            )}
            {(!isMobile || selectedThreadId) &&
                selectedEmail &&
                (isLoadingReplies ? (
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 1,
                        }}
                    >
                        <CircularProgress size="lg" />
                    </Box>
                ) : (
                    <Box sx={{ flex: 1 }}>
                        <EmailContent
                            email={selectedEmail}
                            replies={replies}
                            onBack={isMobile || selectedEmail ? handleBack : undefined}
                            order={order}
                            refetchContent={() => handleEmailSelect(selectedEmail)}
                        />
                    </Box>
                ))}
        </Box>
    )
}
