import { Call } from '@twilio/voice-sdk'
import AudioMotionAnalyzer from 'audiomotion-analyzer'
import React, { useEffect, useRef } from 'react'

interface Props {
    activeCall: Call
}

export default function AudioVisualizer({ activeCall }: Props) {
    const audioMotionElRef = useRef<HTMLDivElement>(null)
    const analyzerRef = useRef<AudioMotionAnalyzer | null>(null)
    const [counter, setCounter] = React.useState(0)

    const cleanUpAnalizer = () => {
        if (analyzerRef.current) {
            analyzerRef.current.disconnectInput()
            analyzerRef.current = null
        }
    }
    useEffect(() => {
        console.error('AudioVisualizer::EFFECT')
        const remoteStream = activeCall.getRemoteStream()

        if (!remoteStream) {
            cleanUpAnalizer()
            setTimeout(() => {
                setCounter(counter + 1)
            }, 1000)
            return
        }
        // Cleanup any existing analyzer instance before creating a new one
        cleanUpAnalizer()

        console.error('AudioVisualizer::INIT')
        if (audioMotionElRef.current) {
            // Initialize audioMotion-analyzer
            analyzerRef.current = new AudioMotionAnalyzer(audioMotionElRef.current, {
                mode: 10,
                channelLayout: 'single',
                fillAlpha: 0.6,
                gradient: 'steelblue',
                lineWidth: 1.5,
                maxFreq: 20000,
                minFreq: 30,
                mirror: -1,
                radial: false,
                reflexAlpha: 1,
                reflexBright: 1,
                reflexRatio: 0.5,
                showPeaks: false,
                showScaleX: false,
            })

            const removeAudioStream = activeCall.getRemoteStream()
            if (removeAudioStream) {
                const source = analyzerRef.current?.audioCtx.createMediaStreamSource(removeAudioStream)
                analyzerRef.current?.connectInput(source)
            }
        }
        return () => {
            console.error('AudioVisualizer::CLEANUP')
            analyzerRef.current?.disconnectInput()
        }
    }, [counter, activeCall])

    return <div ref={audioMotionElRef} style={{ width: '100%', height: '300px', flexGrow: 1 }} />
}
