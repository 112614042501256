import React, { Component, ErrorInfo, ReactNode } from 'react'
import Box from '@mui/joy/Box'
import Alert from '@mui/joy/Alert'
import Button from '@mui/joy/Button'
import Typography from '@mui/joy/Typography'
import WarningIcon from '@mui/icons-material/Warning'

interface Props {
    children: ReactNode
}

interface State {
    hasError: boolean
    error?: Error
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
    }

    public static getDerivedStateFromError(error: Error): State {
        return { hasError: true, error }
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('Uncaught error:', error, errorInfo)
    }

    private handleReset = () => {
        this.setState({ hasError: false, error: undefined })
        window.location.reload() // Reload the page to reset the state
    }

    public render() {
        if (this.state.hasError) {
            return (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: 3,
                        gap: 2,
                        minHeight: 400,
                    }}
                >
                    <Alert variant="soft" color="danger" startDecorator={<WarningIcon />} sx={{ mb: 2, maxWidth: 500 }}>
                        <Box>
                            <Typography level="title-lg" component="h2">
                                Something went wrong
                            </Typography>
                            {this.state.error && (
                                <Typography level="body-sm" sx={{ mt: 1 }}>
                                    {this.state.error.message}
                                </Typography>
                            )}
                        </Box>
                    </Alert>
                    <Button variant="soft" color="neutral" onClick={this.handleReset}>
                        Try Again
                    </Button>
                </Box>
            )
        }

        return this.props.children
    }
}

export default ErrorBoundary
