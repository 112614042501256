import { Avatar, Box, Button, IconButton, Typography } from '@mui/joy'
import { KeyboardArrowDownRounded, Send } from '@mui/icons-material'
import { FetchedEmailObject } from 'pages/dashboard/pages/dispatcher/pages/dispatcher-emails/api/EmailThreadClient'

export interface EmailHeaderProps {
    emailData: FetchedEmailObject
    isReply?: boolean
    onToggleDetails?: () => void
    showDetails?: boolean
    handleParse: () => void
    loading: boolean
    isParsed?: boolean
}

export default function EmailHeader({ emailData, isReply, onToggleDetails, showDetails, handleParse, loading, isParsed }: EmailHeaderProps) {
    return (
        <Box
            onClick={isReply ? onToggleDetails : undefined}
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: 2,
                cursor: isReply ? 'pointer' : 'default',
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}>
                <Avatar>{(emailData.from[0]?.name?.[0] || emailData.from[0]?.email[0] || '').toUpperCase()}</Avatar>
                <Box sx={{ ml: 2 }}>
                    <Typography level="title-sm" textColor="text.primary" mb={0.5}>
                        {emailData.from[0]?.name || emailData.from[0]?.email}
                    </Typography>
                    <Typography level="body-xs" textColor="text.tertiary">
                        {new Date(emailData.date * 1000).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                        })}
                    </Typography>
                </Box>
            </Box>
            {isReply ? (
                <IconButton
                    size="sm"
                    variant="plain"
                    color="neutral"
                    onClick={(e) => {
                        e.stopPropagation()
                        onToggleDetails?.()
                    }}
                    sx={{
                        transform: showDetails ? 'rotate(0deg)' : 'rotate(90deg)',
                        transition: 'transform 0.2s',
                    }}
                >
                    <KeyboardArrowDownRounded />
                </IconButton>
            ) : (
                !isParsed && (
                    <Box
                        sx={{
                            display: 'flex',
                            height: '32px',
                            flexDirection: 'row',
                            gap: 1.5,
                        }}
                    >
                        <Button startDecorator={<Send />} onClick={handleParse} loading={loading}>
                            Parse Email
                        </Button>
                        {/*
                            <Button variant="solid" color="primary" size="sm">
                                Reply
                            </Button>
                            <IconButton size="sm" variant="soft" color="neutral">
                                <ForwardToInboxRounded />
                            </IconButton>
                            <IconButton size="sm" variant="plain" color="danger">
                                <DeleteRounded />
                            </IconButton>
                        */}
                    </Box>
                )
            )}
        </Box>
    )
}
