import axios from 'axios'

export const saveEmailSettings = async (emailSettings: { state: string[]; mountainArea: boolean; price: { min: number; max: number } }) => {
    try {
        const response = await axios.post('/email/email-settings', emailSettings)
        return response
    } catch (error) {
        console.error('Error saving email settings:', error)
    }
}

export const getEmailSettings = async () => {
    try {
        // Perform GET request to fetch email settings
        const response = await axios.get('/email/email-settings')

        // Return the response data
        return response.data
    } catch (error) {
        console.error('Error fetching email settings:', error)
        throw error // Optionally, throw the error if you want to handle it in the calling function
    }
}
