export const apiUrl = () => {
    const apiUrl = `$wss://${process.env.REACT_APP_API_HOST}`;
    return apiUrl;
};

export const wsConnectWithRetry = (
    path: string,
    onOpen: (ws: WebSocket) => void,
    onClose: () => void,
    maxRetryCount = 4
): EventTarget => {
    const emitter = new EventTarget();
    let retryCount = 0;

    const connect = (): WebSocket => {
        const ws = new WebSocket(apiUrl() + '/v1' + path);
        ws.onclose = (e) => {
            console.error('Socket::Internal:: connection closed:', e);
            if (retryCount < maxRetryCount) {
                console.log('Socket::Internal:: retrying connection...');
                const backoffTime = Math.pow(2, retryCount + 1) * 1000;
                setTimeout(() => {
                    const newWs = connect();
                    retryCount++;
                    emitter.dispatchEvent(new CustomEvent('newInstance', { detail: newWs }));
                }, backoffTime);
            } else {
                console.error('Socket::Internal:: max retries exceeded');
                onClose();
            }
        };
        ws.onopen = () => {
            console.log('Socket::Internal:: connection opened');
            retryCount = 0;
            onOpen(ws);
        };
        return ws;
    };
    console.log('Socket::Internal:: connecting to:', apiUrl() + path);
    const ws = connect();
    emitter.dispatchEvent(new CustomEvent('newInstance', { detail: ws }));

    return emitter;
};
