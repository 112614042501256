import { Stack } from '@mui/joy'
import { DispatcherActiveSessions, DispatcherKnowledgeBase, DispatcherModelSelector, NylasEmails } from './components'

export default function DispatcherSettingsPage() {
    return (
        <Stack overflow="auto" spacing={4}>
            <NylasEmails />
            <DispatcherKnowledgeBase />
            <DispatcherModelSelector />
            <DispatcherActiveSessions />
        </Stack>
    )
}
