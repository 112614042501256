export interface EmailAccount {
    id: string
    email: string
    provider: string
    grantId: string
    status: 'valid' | 'invalid' | 'revoked'
}

export interface AuthUrlResponse {
    authUrl: string
}

export interface TokenExchangeResponse {
    success: boolean
    grant_id: string
    access_token: string
}

export interface ConnectionStatusResponse {
    isConnected: boolean
    status: string
}

export class ApiError extends Error {
    constructor(
        message: string,
        public status?: number
    ) {
        super(message)
        this.name = 'ApiError'
    }
}
