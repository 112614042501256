import { useState, useEffect } from 'react'
import { Box, Typography, Select, Option, ListItemDecorator } from '@mui/joy'
import { googleOAuthService } from '../../../pages/dashboard/pages/dispatcher/pages/dispatcher-settings/components/dispatcher-knowledge-base/services/GoogleOAuthService'
import { GoogleDriveClient } from '../../../pages/dashboard/pages/dispatcher/pages/dispatcher-settings/components/dispatcher-knowledge-base/api/GoogleDriveClient'
import GoogleIcon from '@mui/icons-material/Google'
import AddIcon from '@mui/icons-material/Add'

interface GoogleAccount {
    email: string
}

export default function GoogleAccountsSelect() {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<{ numeoApiError?: string; googleAuthFlowError?: string } | null>(null)
    const [accounts, setAccounts] = useState<GoogleAccount[]>([])
    const [selectedEmail, setSelectedEmail] = useState<string>('')

    const fetchAccounts = async () => {
        try {
            const response = await GoogleDriveClient.getConnectedAccounts()
            setAccounts(response.accounts)
            if (response.accounts.length > 0) {
                setSelectedEmail(response.accounts[0].email)
            }
        } catch (err) {
            console.error('Failed to fetch Google accounts:', err)
            setError({ numeoApiError: 'Failed to load connected accounts' })
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchAccounts()
    }, [])

    const handleGoogleResponse = async (response: google.accounts.oauth2.CodeResponse, deniedScopes: string[]) => {
        try {
            if (response.error) {
                throw new Error(`${response.error}: ${response.error_description || 'Unknown error'}`)
            }

            // Check if any required scopes were denied
            if (deniedScopes.length > 0) {
                const scopeNames = deniedScopes.map((scope) => {
                    // Convert scope URLs to readable names
                    if (scope.includes('drive.readonly')) return 'Drive read access'
                    if (scope.includes('drive.metadata.readonly')) return 'Drive metadata access'
                    if (scope.includes('userinfo.email')) return 'Email access'
                    return scope
                })
                throw new Error(`The following permissions are required but were denied: ${scopeNames.join(', ')}`)
            }

            setIsLoading(true)
            // Connect Google account using the authorization code
            await GoogleDriveClient.connectGoogleDrive(response.code)

            // Refresh the accounts list
            await fetchAccounts()
        } catch (err) {
            setError({ googleAuthFlowError: err instanceof Error ? err.message : 'Failed to authenticate with Google' })
            setIsLoading(false)
        }
    }

    const handleAddAccount = async () => {
        try {
            setError(null)
            await googleOAuthService.requestAuthCode({
                onSuccess: handleGoogleResponse,
                onError: ({ message }) => {
                    setError({ googleAuthFlowError: message })
                },
            })
        } catch (err) {
            console.error('Error initializing Google authentication:', err)
            setError({ googleAuthFlowError: err instanceof Error ? err.message : 'Failed to initialize Google authentication' })
        }
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Select
                placeholder={isLoading ? 'Loading accounts...' : 'Google Accounts'}
                value={selectedEmail}
                disabled={isLoading}
                onChange={(_, value) => {
                    if (value === 'add-account') {
                        handleAddAccount()
                    } else {
                        setSelectedEmail(value as string)
                    }
                }}
                sx={{
                    width: '100%',
                    '--Select-decoratorChildHeight': '32px',
                }}
            >
                {accounts.map((account) => (
                    <Option
                        key={account.email}
                        value={account.email}
                        sx={{
                            '--ListItemDecorator-size': '32px',
                        }}
                    >
                        <ListItemDecorator>
                            <GoogleIcon sx={{ color: 'neutral.500' }} />
                        </ListItemDecorator>
                        <Typography level="body-sm">{account.email}</Typography>
                    </Option>
                ))}
                <Option
                    value="add-account"
                    sx={{
                        '--ListItemDecorator-size': '32px',
                        color: 'primary.500',
                    }}
                >
                    <ListItemDecorator>
                        <AddIcon sx={{ color: 'primary.500' }} />
                    </ListItemDecorator>
                    <Typography level="body-sm">Add account</Typography>
                </Option>
            </Select>

            <Typography color="danger" level="body-sm">
                {error &&
                    (error.googleAuthFlowError
                        ? `There was an error authenticating with Google: ${error.googleAuthFlowError}`
                        : `Numeo API error: ${error?.numeoApiError}`)}
            </Typography>
        </Box>
    )
}
