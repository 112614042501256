import { loadScript } from '../../../utils/script'

/**
 * URLs for Google API scripts
 */
const GOOGLE_SCRIPT_URLS = {
    identity: 'https://accounts.google.com/gsi/client',
    api: 'https://apis.google.com/js/api.js',
} as const

/**
 * Load Google Identity Services script
 * This script provides the 'google' global object
 */
export const loadGoogleIdentityScripts = async (): Promise<void> => {
    try {
        await loadScript(GOOGLE_SCRIPT_URLS.identity)
        // Wait for google object to be available
        await new Promise<void>((resolve) => {
            const checkGoogle = () => {
                if (window.google) {
                    resolve()
                } else {
                    setTimeout(checkGoogle, 100)
                }
            }
            checkGoogle()
        })
    } catch (error) {
        throw new Error('Failed to load Google Identity Services script')
    }
}

/**
 * Load Google API client script
 * This script provides the 'gapi' global object
 */
export const loadGoogleApiScripts = async (): Promise<void> => {
    try {
        await loadScript(GOOGLE_SCRIPT_URLS.api)
        // Wait for gapi object to be available
        await new Promise<void>((resolve) => {
            const checkGapi = () => {
                if (window.gapi) {
                    resolve()
                } else {
                    setTimeout(checkGapi, 100)
                }
            }
            checkGapi()
        })
    } catch (error) {
        throw new Error('Failed to load Google API client script')
    }
}
