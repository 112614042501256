import { Button, Stack, ToggleButtonGroup, Typography } from '@mui/joy'
import { ConversationBackend, VoicePipeline } from 'const'
import { useState } from 'react'

export default function DispatcherModelSelector() {
    const [conversationModel, setConversationModel] = useState<ConversationBackend>(ConversationBackend.OpenAiRealtimeV1)
    const [voiceModel, setVoiceModel] = useState<VoicePipeline>(VoicePipeline.OpenAiRealtimeV1)

    return (
        <Stack direction="column" alignItems={'center'} alignContent={'center'} gap={6} sx={{ height: '100%' }}>
            <Stack alignItems={'center'} gap={2}>
                <Typography level="h1">Conversation choice</Typography>
                <ToggleButtonGroup
                    spacing={{ xs: 0, md: 2, lg: 3 }}
                    value={conversationModel}
                    onChange={(event, newValue) => newValue && setConversationModel(newValue)}
                >
                    <Button value={ConversationBackend.OpenAiRealtimeV1} color="primary" sx={{ height: 150, width: 130 }}>
                        OpenAI Realtime V1
                    </Button>
                    <Button disabled value={ConversationBackend.LLAMA_3b} color="primary" sx={{ height: 150, width: 130 }}>
                        Meta LLAMA 3b
                        <br />
                        (coming soon)
                    </Button>
                    <Button disabled value={ConversationBackend.CLAUDE_SONET} color="primary" sx={{ height: 150, width: 130 }}>
                        Claude Sonet
                        <br />
                        (coming soon)
                    </Button>
                </ToggleButtonGroup>
            </Stack>
            <Stack alignItems={'center'} gap={2}>
                <Typography level="h1">Voice pipeline</Typography>
                <ToggleButtonGroup spacing={{ xs: 0, md: 2, lg: 3 }} value={voiceModel} onChange={(event, newValue) => newValue && setVoiceModel(newValue)}>
                    <Button value={VoicePipeline.OpenAiRealtimeV1} color="primary" sx={{ height: 150, width: 130 }}>
                        OpenAI Realtime V1
                    </Button>
                    <Button disabled value={VoicePipeline.WhisperElevenLabs} color="primary" sx={{ height: 150, width: 130 }}>
                        STT: Wisper
                        <br />
                        TTS: ElevenLabs
                        <br />
                        (coming soon)
                    </Button>
                </ToggleButtonGroup>
            </Stack>
        </Stack>
    )
}
