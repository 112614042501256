import { Avatar, Box, ListDivider, ListItem, ListItemButton, ListItemDecorator, Typography } from '@mui/joy'
import { Chat } from '@mui/icons-material'
import React from 'react'
import { FetchedEmail } from '../../../api/EmailThreadClient'

interface EmailCardProps {
    thread: FetchedEmail
    isSelected: boolean
    onClick?: () => void
    selectedThreadId: string | null
}

export default function EmailCard({ thread, isSelected, onClick, selectedThreadId }: EmailCardProps) {
    const { from } = thread.emailData.object
    const senderName = from[0]?.name || from[0]?.email || ''
    const initial = senderName[0]?.toUpperCase() || ''

    return (
        <React.Fragment>
            <ListItem>
                <ListItemButton
                    selected={isSelected}
                    onClick={onClick}
                    sx={{
                        p: 2,
                    }}
                >
                    <ListItemDecorator sx={{ alignSelf: 'flex-start' }}>
                        <Avatar
                            variant="soft"
                            color="neutral"
                            sx={{
                                '--Avatar-size': '32px',
                            }}
                        >
                            {initial}
                        </Avatar>
                    </ListItemDecorator>
                    <Box sx={{ pl: 2, width: '100%' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mb: 0.5,
                            }}
                        >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Typography
                                    level="title-sm"
                                    sx={{
                                        fontWeight: 600,
                                        color: 'text.secondary',
                                        width: selectedThreadId ? 200 : 'auto',
                                        display: '-webkit-box',
                                        WebkitLineClamp: 1,
                                        WebkitBoxOrient: 'vertical',
                                        overflow: 'hidden',
                                    }}
                                >
                                    {senderName}
                                </Typography>
                                {(thread.replyCount || 0) > 0 && (
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                        <Chat
                                            sx={{
                                                fontSize: 14,
                                                color: 'text.tertiary',
                                            }}
                                        />
                                        <Typography
                                            level="body-xs"
                                            sx={{
                                                color: 'text.tertiary',
                                            }}
                                        >
                                            {thread.replyCount}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                            <Typography
                                level="body-xs"
                                sx={{
                                    color: 'text.tertiary',
                                }}
                            >
                                {selectedThreadId ? null : new Date(thread.emailData.object.date * 1000).toLocaleDateString()}
                            </Typography>
                        </Box>
                        <Typography
                            level="body-sm"
                            sx={{
                                fontWeight: 500,
                                color: 'text.secondary',
                                mb: 0.5,
                                display: '-webkit-box',
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                            }}
                        >
                            {selectedThreadId ? null : thread.emailData.object.subject}
                        </Typography>
                        <Typography
                            level="body-sm"
                            sx={{
                                color: 'text.secondary',
                                display: '-webkit-box',
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                            }}
                        >
                            {selectedThreadId ? null : thread.emailData.object.snippet}
                        </Typography>
                    </Box>
                </ListItemButton>
            </ListItem>
            <ListDivider />
        </React.Fragment>
    )
}
