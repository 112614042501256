import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { Sheet } from '@mui/joy'
import { memo } from 'react'
import decodeHtmlEntitiesInHtml from 'utils/script'

interface RichTextEditorProps {
    value: string
    onChange: (value: string) => void
    placeholder?: string
    minHeight?: number | string
    readOnly?: boolean
    showToolbar?: boolean
}

const TextEditor = ({ value, onChange, placeholder = 'Start typing...', minHeight = 200, readOnly = false, showToolbar = false }: RichTextEditorProps) => {
    const modules = {
        toolbar: showToolbar
            ? [[{ header: [1, 2, false] }], ['bold', 'italic', 'underline', 'strike'], [{ list: 'ordered' }, { list: 'bullet' }], ['clean']]
            : false,
    }

    const formats = showToolbar ? ['header', 'bold', 'italic', 'underline', 'strike', 'list', 'bullet'] : []

    const sanitizeHtmlToText = (html: string): void => {
        const data = decodeHtmlEntitiesInHtml(html)
        onChange(data)
    }

    return (
        <Sheet
            variant="soft"
            sx={{
                borderRadius: 'sm',
                '.ql-container.ql-snow': {
                    border: 'none',
                    fontSize: '1rem',
                    fontFamily: 'inherit',
                },
                '.ql-toolbar.ql-snow': {
                    border: 'none',
                    borderBottom: showToolbar ? '1px solid' : 'none',
                    borderColor: 'divider',
                },
                '.ql-editor': {
                    minHeight: minHeight,
                    padding: 2,
                    '&:focus': {
                        outline: 'none',
                    },
                    '&.ql-blank::before': {
                        fontStyle: 'normal',
                        color: 'neutral.500',
                    },
                },
                '&:focus-within': {
                    borderColor: 'primary.500',
                    boxShadow: (theme) => `0 0 0 3px ${theme.vars.palette.primary.softBg}`,
                },
            }}
        >
            <ReactQuill
                value={value}
                onChange={sanitizeHtmlToText}
                modules={modules}
                formats={formats}
                placeholder={placeholder}
                theme="snow"
                readOnly={readOnly}
            />
        </Sheet>
    )
}

export const RichTextEditor = memo(TextEditor)
