import { Box, Typography } from '@mui/joy'

export default function SelectEmailText() {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
            }}
        >
            <Typography level="body-lg" textColor="text.tertiary">
                Select an email to read
            </Typography>
        </Box>
    )
}
