import { Box, CircularProgress, Stack, Typography } from '@mui/joy'
import { ActiveSession } from '@numeo/types'
import { useEffect, useState } from 'react'
import { apiUrl } from 'utils/network'
import { DispatcherSessionsTable } from './components'

export default function DispatcherActiveSessions() {
    const [sessions, setSessions] = useState<ActiveSession[] | null>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<string | null>(null)

    useEffect(() => {
        let ws: WebSocket | null
        const fetchSessions = () => {
            setLoading(true)
            try {
                ws = new WebSocket(apiUrl() + '/v1/admin/sessions')
                ws.onmessage = (event) => {
                    console.log('Socket message received:', event.data)
                    setSessions(JSON.parse(event.data))
                }
                ws.onclose = () => console.log('Socket connection closed')
                ws.onerror = (error) => console.error('Socket connection error:', error)
                ws.onopen = () => {
                    console.error('WebSocket::ActiveSessions:Connected')
                    ws?.send('getActiveSessions')
                }
            } catch (error) {
                console.error('ActiveSessions:Error fetching sessions:', error)
                setError((error as Error).message)
            } finally {
                setLoading(false)
            }
        }

        fetchSessions()
        return () => {
            console.error('WebSocket::ActiveSessions:Cleanup')
            ws?.close()
            ws = null
        }
    }, [])

    return (
        <Stack direction="column">
            <Typography level="h4" sx={{ pb: 2 }}>
                Active Sessions
            </Typography>
            {loading && (
                <Box display="flex" justifyContent="center">
                    <Stack spacing={2}>
                        <CircularProgress />
                        <Typography>Loading...</Typography>
                    </Stack>
                </Box>
            )}
            {error && <Typography>{JSON.stringify(error, null, 2)}</Typography>}
            {sessions && <DispatcherSessionsTable sessions={sessions} />}
        </Stack>
    )
}
