import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import DeleteIcon from '@mui/icons-material/Delete'
import FilterListIcon from '@mui/icons-material/FilterList'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import Box from '@mui/joy/Box'
import Checkbox from '@mui/joy/Checkbox'
import FormControl from '@mui/joy/FormControl'
import FormLabel from '@mui/joy/FormLabel'
import IconButton from '@mui/joy/IconButton'
import Link from '@mui/joy/Link'
import Option from '@mui/joy/Option'
import Select from '@mui/joy/Select'
import Sheet from '@mui/joy/Sheet'
import Table from '@mui/joy/Table'
import Tooltip from '@mui/joy/Tooltip'
import Typography from '@mui/joy/Typography'
import { visuallyHidden } from '@mui/utils'
import { ActiveSession } from '@numeo/types'
import * as React from 'react'
import { FC } from 'react'

function labelDisplayedRows({ from, to, count }: { from: number; to: number; count: number }) {
    return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`
}

type Order = 'asc' | 'desc'

interface HeadCell {
    disablePadding: boolean
    id: keyof ActiveSession
    label: string
    numeric: boolean
}

const headCells: readonly HeadCell[] = [
    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'User Identifier',
    },
    {
        id: 'conversationBackend',
        numeric: true,
        disablePadding: false,
        label: 'Conversational Backend',
    },
    {
        id: 'voicePipeline',
        numeric: true,
        disablePadding: false,
        label: 'Voice pipeline',
    },
    {
        id: 'incoming',
        numeric: true,
        disablePadding: false,
        label: 'Incoming call',
    },
    {
        id: 'startedAt',
        numeric: true,
        disablePadding: false,
        label: 'Call started at',
    },
]

interface EnhancedTableProps {
    numSelected: number
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof ActiveSession) => void
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
    order: Order
    orderBy: string
    rowCount: number
}

const EnhancedTableHead: FC<EnhancedTableProps> = (props) => {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props
    const createSortHandler = (property: keyof ActiveSession) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property)
    }

    return (
        <thead>
            <tr>
                <th>
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        slotProps={{
                            input: {
                                'aria-label': 'select all desserts',
                            },
                        }}
                        sx={{ verticalAlign: 'sub' }}
                    />
                </th>
                {headCells.map((headCell) => {
                    const active = orderBy === headCell.id
                    return (
                        <th key={headCell.id} aria-sort={active ? ({ asc: 'ascending', desc: 'descending' } as const)[order] : undefined}>
                            {}
                            <Link
                                underline="none"
                                color="neutral"
                                textColor={active ? 'primary.plainColor' : undefined}
                                component="button"
                                onClick={createSortHandler(headCell.id)}
                                startDecorator={headCell.numeric ? <ArrowDownwardIcon sx={[active ? { opacity: 1 } : { opacity: 0 }]} /> : null}
                                endDecorator={!headCell.numeric ? <ArrowDownwardIcon sx={[active ? { opacity: 1 } : { opacity: 0 }]} /> : null}
                                sx={{
                                    fontWeight: 'lg',

                                    '& svg': {
                                        transition: '0.2s',
                                        transform: active && order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                                    },

                                    '&:hover': { '& svg': { opacity: 1 } },
                                }}
                            >
                                {headCell.label}
                                {active ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </Link>
                        </th>
                    )
                })}
            </tr>
        </thead>
    )
}
interface EnhancedTableToolbarProps {
    numSelected: number
}
const EnhancedTableToolbar: FC<EnhancedTableToolbarProps> = (props) => {
    const { numSelected } = props
    return (
        <Box
            sx={[
                {
                    display: 'flex',
                    alignItems: 'center',
                    py: 1,
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    borderTopLeftRadius: 'var(--unstable_actionRadius)',
                    borderTopRightRadius: 'var(--unstable_actionRadius)',
                },
                numSelected > 0 && {
                    bgcolor: 'background.level1',
                },
            ]}
        >
            {numSelected > 0 ? (
                <Typography sx={{ flex: '1 1 100%' }} component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography level="body-lg" sx={{ flex: '1 1 100%' }} id="tableTitle" component="div">
                    Active Call sessions
                </Typography>
            )}
            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton size="sm" color="danger" variant="solid">
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton size="sm" variant="outlined" color="neutral">
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Box>
    )
}
export default function DispatcherSessionsTable({ sessions }: Props) {
    const [order, setOrder] = React.useState<Order>('asc')
    const [orderBy, setOrderBy] = React.useState<keyof ActiveSession>('startedAt')
    const [selected, setSelected] = React.useState<readonly string[]>([])
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(25)
    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ActiveSession) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = sessions.map((n) => n.id)
            setSelected(newSelected)
            return
        }
        setSelected([])
    }
    const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
        const selectedIndex = selected.indexOf(name)
        let newSelected: readonly string[] = []
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
        }
        setSelected(newSelected)
    }
    const handleChangePage = (newPage: number) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (
        event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element> | React.FocusEvent<Element, Element> | null,
        value: number | null
    ) => {
        setRowsPerPage(parseInt(value!.toString(), 10))
    }
    const getLabelDisplayedRowsTo = () => {
        if (sessions.length === -1) {
            return (page + 1) * rowsPerPage
        }
        return rowsPerPage === -1 ? sessions.length : Math.min(sessions.length, (page + 1) * rowsPerPage)
    }
    // Avoid a layout jump when reaching the last page with empty sessions.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - sessions.length) : 0
    return (
        <Sheet variant="outlined" sx={{ width: '100%', boxShadow: 'sm', borderRadius: 'sm' }}>
            <EnhancedTableToolbar numSelected={selected.length} />
            <Table
                aria-labelledby="tableTitle"
                hoverRow
                sx={{
                    '--TableCell-headBackground': 'transparent',
                    '--TableCell-selectedBackground': (theme) => theme.vars.palette.success.softBg,
                    '& thead th:nth-child(1)': {
                        width: '40px',
                    },
                    '& thead th:nth-child(2)': {
                        width: '30%',
                    },
                    '& tr > *:nth-child(n+3)': { textAlign: 'right' },
                }}
            >
                <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={sessions.length}
                />
                <tbody>
                    {[...sessions]
                        // .sort(getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                            const isItemSelected = selected.includes(row.id)
                            const labelId = `enhanced-table-checkbox-${index}`

                            return (
                                <tr
                                    onClick={(event) => handleClick(event, row.id)}
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.id}
                                    // selected={isItemSelected}
                                    style={
                                        isItemSelected
                                            ? ({
                                                  '--TableCell-dataBackground': 'var(--TableCell-selectedBackground)',
                                                  '--TableCell-headBackground': 'var(--TableCell-selectedBackground)',
                                              } as React.CSSProperties)
                                            : {}
                                    }
                                >
                                    <th scope="row">
                                        <Checkbox
                                            checked={isItemSelected}
                                            slotProps={{
                                                input: {
                                                    'aria-labelledby': labelId,
                                                },
                                            }}
                                            sx={{ verticalAlign: 'top' }}
                                        />
                                    </th>
                                    <th id={labelId} scope="row">
                                        {row.id}
                                    </th>
                                    <td>{row.conversationBackend}</td>
                                    <td>{row.voicePipeline}</td>
                                    <td>{JSON.stringify(row.incoming, null, 2)}</td>
                                    <td>{row.startedAt}</td>
                                </tr>
                            )
                        })}
                    {emptyRows > 0 && (
                        <tr
                            style={
                                {
                                    height: `calc(${emptyRows} * 40px)`,
                                    '--TableRow-hoverBackground': 'transparent',
                                } as React.CSSProperties
                            }
                        >
                            <td colSpan={6} aria-hidden />
                        </tr>
                    )}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={6}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 2,
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <FormControl orientation="horizontal" size="sm">
                                    <FormLabel>Rows per page:</FormLabel>
                                    <Select onChange={handleChangeRowsPerPage} value={rowsPerPage}>
                                        <Option value={5}>5</Option>
                                        <Option value={10}>10</Option>
                                        <Option value={25}>25</Option>
                                    </Select>
                                </FormControl>
                                <Typography sx={{ textAlign: 'center', minWidth: 80 }}>
                                    {labelDisplayedRows({
                                        from: sessions.length === 0 ? 0 : page * rowsPerPage + 1,
                                        to: getLabelDisplayedRowsTo(),
                                        count: sessions.length === -1 ? -1 : sessions.length,
                                    })}
                                </Typography>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <IconButton
                                        size="sm"
                                        color="neutral"
                                        variant="outlined"
                                        disabled={page === 0}
                                        onClick={() => handleChangePage(page - 1)}
                                        sx={{ bgcolor: 'background.surface' }}
                                    >
                                        <KeyboardArrowLeftIcon />
                                    </IconButton>
                                    <IconButton
                                        size="sm"
                                        color="neutral"
                                        variant="outlined"
                                        disabled={sessions.length !== -1 ? page >= Math.ceil(sessions.length / rowsPerPage) - 1 : false}
                                        onClick={() => handleChangePage(page + 1)}
                                        sx={{ bgcolor: 'background.surface' }}
                                    >
                                        <KeyboardArrowRightIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        </td>
                    </tr>
                </tfoot>
            </Table>
        </Sheet>
    )
}

interface Props {
    sessions: ActiveSession[]
}
