import { useState } from 'react'
import Input from '@mui/joy/Input'
import IconButton from '@mui/joy/IconButton'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { InputProps } from '@mui/joy/Input'
import FormLabel from '@mui/joy/FormLabel'
import { FieldError, UseFormRegisterReturn } from 'react-hook-form'
import { FormField } from 'components/common'

type PasswordFieldProps = Omit<InputProps, 'type'> & {
    label?: string
    formError?: FieldError
    registration: UseFormRegisterReturn
    autoComplete: string
}

export default function PasswordField({ label = 'Password', formError, registration, autoComplete }: PasswordFieldProps) {
    const [showPassword, setShowPassword] = useState(false)

    return (
        <FormField error={formError}>
            <FormLabel>{label}</FormLabel>
            <Input
                autoComplete={autoComplete}
                endDecorator={
                    <IconButton variant="plain" color="neutral" onClick={() => setShowPassword(!showPassword)} sx={{ borderRadius: '50%' }}>
                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                }
                {...registration}
                type={showPassword ? 'text' : 'password'}
            />
        </FormField>
    )
}
