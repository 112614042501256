import { Add } from '@mui/icons-material'
import { Box, Card, CircularProgress, Grid, Typography } from '@mui/joy'

interface AddKnowledgeBaseBtnProps {
    isLoading: boolean
    onClick: () => void
}

export default function AddKnowledgeBaseBtn({ onClick, isLoading }: AddKnowledgeBaseBtnProps) {
    return (
        <Grid xs={12} sm={6} md={4}>
            <Card
                variant="outlined"
                sx={{
                    minHeight: '159px',
                    cursor: isLoading ? 'wait' : 'pointer',
                    p: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '&:hover': isLoading
                        ? {}
                        : {
                              borderColor: 'primary.outlinedHoverBorder',
                              bgcolor: 'background.level1',
                              '& .add-icon': {
                                  color: 'primary.600',
                                  transform: 'scale(1.1)',
                              },
                          },
                }}
                onClick={isLoading ? undefined : onClick}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 1.5,
                    }}
                >
                    {isLoading ? (
                        <CircularProgress size="sm" sx={{ mb: 0.5 }} />
                    ) : (
                        <Add
                            className="add-icon"
                            sx={{
                                fontSize: 28,
                                color: 'primary.500',
                                transition: 'all 0.2s ease-in-out',
                            }}
                        />
                    )}
                    <Typography
                        level="title-sm"
                        sx={{
                            color: 'text.secondary',
                            fontWeight: 600,
                        }}
                    >
                        {isLoading ? 'Connecting...' : 'Add Knowledge Base'}
                    </Typography>
                </Box>
            </Card>
        </Grid>
    )
}
