import { ApplicationOrder, OrderDetails } from '@numeo/types'
import { Box, Button, Card, Chip, Divider, List, ListItem, ListItemContent, Typography, FormControl, Select, FormLabel, Option, IconButton } from '@mui/joy'
import { LocalShipping, Person, Inventory, Send, Timeline, StraightRounded, AttachMoney, LocationOn, Edit } from '@mui/icons-material'
import { useState, useRef } from 'react'
import { EmailThreadClient } from '../../../../../api/EmailThreadClient'
import dayjs from 'dayjs'

export function LocationDetails({ location, title }: { location: OrderDetails['pickup'] | OrderDetails['delivery']; title: string }) {
    return (
        <Box>
            <Typography level="title-sm" startDecorator={<LocationOn />} sx={{ mb: 1 }}>
                {title}
            </Typography>
            <List size="sm" sx={{ '--ListItem-paddingY': '4px' }}>
                <ListItem>
                    <ListItemContent>{location.address}</ListItemContent>
                </ListItem>
                <ListItem>
                    <ListItemContent>{`${location.city}, ${location.state}`}</ListItemContent>
                </ListItem>
                {(location.date || location.time) && (
                    <ListItem>
                        <ListItemContent>{[location.date, location.time].filter(Boolean).join(' ')}</ListItemContent>
                    </ListItem>
                )}
            </List>
        </Box>
    )
}

interface EmailDetailProps {
    parsedData: ApplicationOrder | null
    setGeneratedOffer: (
        offer:
            | {
                  subject: string
                  body: string
              }
            // FIXME: Please replace 'any' with the correct type
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            | any
    ) => void
    isOfferGenerated: boolean
    isOfferSent: boolean
}

export default function EmailDetail({ parsedData, setGeneratedOffer, isOfferGenerated, isOfferSent }: EmailDetailProps) {
    const [loading, setLoading] = useState(false)
    const [emailTone, setEmailTone] = useState<'official' | 'dispatcher-style'>('official')
    const [isEditingPrice, setIsEditingPrice] = useState(false)
    const [pricePerMile, setPricePerMile] = useState(parsedData?.details.recommendedPricePerMile || '')
    const priceRef = useRef<HTMLInputElement>(null)

    const handlePriceEdit = () => {
        setIsEditingPrice(true)
        setTimeout(() => {
            if (priceRef.current) {
                priceRef.current.focus()
            }
        }, 0)
    }

    const handlePriceChange = () => {
        setIsEditingPrice(false)
    }

    const handleGenerateOffer = async () => {
        setLoading(true)
        try {
            const response = await EmailThreadClient.generateEmailOfferById(parsedData!._id, {
                tone: emailTone,
                pricePerMile: parseFloat(`${pricePerMile}`),
            })
            setGeneratedOffer(response)

            setTimeout(() => {
                window.scrollTo({
                    top: document.body.scrollHeight + 500,
                    behavior: 'smooth',
                })
            }, 100)
        } catch (error) {
            console.error('Error generating offer:', error)
        } finally {
            setLoading(false)
        }
    }

    const onPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPricePerMile(event.target.value)
        event.target.style.width = `${event.target.value.length}ch`
    }

    if (!parsedData) return null

    return (
        <>
            <Card variant="outlined" sx={{ p: 3, height: '100%', minWidth: 350 }}>
                <Typography level="title-lg" startDecorator={<LocalShipping />} sx={{ mb: 3 }}>
                    Order Details
                </Typography>

                <Divider sx={{ my: 2 }} />

                <Box>
                    <Typography level="title-sm" startDecorator={<Person />} sx={{ mb: 1 }}>
                        Contact Information
                    </Typography>
                    <List size="sm" sx={{ '--ListItem-paddingY': '4px' }}>
                        <ListItem>
                            <ListItemContent>Name: {parsedData.details.contact.name}</ListItemContent>
                        </ListItem>
                        {parsedData.details.contact.company && (
                            <ListItem>
                                <ListItemContent>Company: {parsedData.details.contact.company}</ListItemContent>
                            </ListItem>
                        )}
                        <ListItem>
                            <ListItemContent>Email: {parsedData.details.contact.email}</ListItemContent>
                        </ListItem>
                        <ListItem>
                            <ListItemContent>Phone: {parsedData.details.contact.phone}</ListItemContent>
                        </ListItem>
                    </List>
                </Box>

                <Divider sx={{ my: 2 }} />

                <LocationDetails location={parsedData?.details.pickup} title="Pickup Location" />

                <Divider sx={{ my: 2 }} />

                <LocationDetails location={parsedData?.details.delivery} title="Delivery Location" />

                <Divider sx={{ my: 2 }} />

                <Box>
                    <Typography level="title-sm" startDecorator={<Inventory />} sx={{ mb: 1 }}>
                        Cargo Details
                    </Typography>
                    <List size="sm" sx={{ '--ListItem-paddingY': '4px' }}>
                        {parsedData.details.cargo.cargoType && (
                            <ListItem>
                                <ListItemContent>Type: {parsedData.details.cargo.cargoType}</ListItemContent>
                            </ListItem>
                        )}
                        {(parsedData.details.cargo.specialRequirements?.length || 0) > 0 && (
                            <ListItem>
                                <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
                                    {parsedData.details.cargo.specialRequirements?.map((req, index) => (
                                        <Chip key={index} size="sm" variant="soft" color="neutral">
                                            {req}
                                        </Chip>
                                    ))}
                                </Box>
                            </ListItem>
                        )}
                    </List>
                </Box>

                <Divider sx={{ my: 2 }} />

                <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', flexWrap: 'wrap' }}>
                        {parsedData.details.miles && (
                            <Chip variant="soft" color="neutral" startDecorator={<StraightRounded />}>
                                {Math.round(parsedData.details.miles)} miles
                            </Chip>
                        )}
                        {parsedData.details.duration && (
                            <Chip variant="soft" color="neutral" startDecorator={<Timeline />}>
                                {dayjs.duration(parseInt(parsedData.details.duration.replace('s', '')), 'seconds').humanize()}
                            </Chip>
                        )}
                        {parsedData.details.recommendedPricePerMile && (
                            <Chip
                                variant="soft"
                                color="neutral"
                                startDecorator={<AttachMoney />}
                                onClick={handlePriceEdit}
                                endDecorator={
                                    <IconButton
                                        size="sm"
                                        variant="plain"
                                        color="neutral"
                                        sx={{ ml: -1, mr: -1, transform: 'scale(0.8)', visibility: !isEditingPrice ? 'visible' : 'hidden' }}
                                    >
                                        <Edit />
                                    </IconButton>
                                }
                            >
                                <input
                                    ref={priceRef}
                                    disabled={!isEditingPrice}
                                    onBlur={handlePriceChange}
                                    style={{
                                        outline: 'none',
                                        minWidth: '20px',
                                        display: 'inline-block',
                                        background: 'transparent',
                                        border: 'none',
                                        color: 'inherit',
                                        fontFamily: 'inherit',
                                        fontSize: 'inherit',
                                        padding: 0,
                                        margin: 0,
                                        textAlign: 'center',
                                        width: '30px',
                                    }}
                                    suppressContentEditableWarning
                                    value={pricePerMile}
                                    onChange={onPriceChange}
                                />
                                /mile
                            </Chip>
                        )}
                    </Box>
                </Box>

                <Divider sx={{ my: 2 }} />

                <Box>
                    <FormControl sx={{ mb: 2 }}>
                        <FormLabel>Email Tone</FormLabel>
                        <Select defaultValue="official" value={emailTone} onChange={(_, value) => setEmailTone(value as 'official' | 'dispatcher-style')}>
                            <Option value="official">Official</Option>
                            <Option value="dispatcher-style">Dispatcher Style</Option>
                        </Select>
                    </FormControl>
                </Box>

                <Divider sx={{ my: 2 }} />

                {!isOfferSent && (
                    <Button
                        fullWidth
                        size="lg"
                        color="success"
                        startDecorator={<Send />}
                        loading={loading}
                        onClick={handleGenerateOffer}
                        sx={{
                            mt: 2,
                            boxShadow: 'sm',
                            '&:hover': { boxShadow: 'md' },
                        }}
                    >
                        {isOfferGenerated ? 'Regenerate Offer' : 'Generate Offer'}
                    </Button>
                )}
            </Card>
        </>
    )
}
