import { object, string } from 'yup'
import { GenerateEmailByOrderIdRequest, ParseFetchedEmailRequest } from './types'

export const parseFetchedEmailSchema = object<ParseFetchedEmailRequest>({
    emailId: string().required('Email ID is required'),
})

export const generateEmailByOrderIdSchema = object<GenerateEmailByOrderIdRequest>({
    orderId: string().required('Email ID is required'),
})
