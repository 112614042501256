import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel'
import Input from '@mui/joy/Input'
import Typography from '@mui/joy/Typography'
import { ForgotPasswordData, forgotPasswordSchema } from '@numeo/types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import Alert from '@mui/joy/Alert'
import CircularProgress from '@mui/joy/CircularProgress'
import Link from '@mui/joy/Link'
import { AxiosError } from 'axios'
import { FormField } from 'components/common'
import { useAuth } from 'hooks/auth'

export default function ForgotPasswordPage() {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const [success, setSuccess] = useState(false)
    const { forgotPassword } = useAuth()

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ForgotPasswordData>({
        resolver: yupResolver(forgotPasswordSchema),
        defaultValues: {},
    })

    const onSubmit = async (formData: ForgotPasswordData) => {
        setIsLoading(true)
        setError(null)

        try {
            await forgotPassword(formData)
            setSuccess(true)
        } catch (err) {
            setError(err instanceof AxiosError ? err.response?.data?.message || 'Failed to sign in' : 'Failed to send reset email. Please try again.')
        }

        setIsLoading(false)
    }

    return (
        <Box
            component="main"
            sx={{
                my: 'auto',
                py: 2,
                pb: 5,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: 400,
                maxWidth: '100%',
                mx: 'auto',
                borderRadius: 'sm',
                '& form': {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                },
                [`& .${formLabelClasses.asterisk}`]: {
                    visibility: 'hidden',
                },
            }}
        >
            <div>
                <Typography component="h1" fontSize="xl2" fontWeight="lg">
                    Forgot Password
                </Typography>
                <Typography level="body-sm" sx={{ my: 1, mb: 3 }}>
                    Enter your email address to reset your password
                </Typography>
            </div>

            {error && (
                <Alert color="danger" variant="soft">
                    {error}
                </Alert>
            )}

            {success ? (
                <>
                    <Alert color="success" variant="soft">
                        Password reset instructions have been sent to your email.
                    </Alert>
                    <Link href="/auth/sign-in" fontWeight="lg">
                        <Button fullWidth>Return to Sign In</Button>
                    </Link>
                </>
            ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormField error={errors.email}>
                        <FormLabel>Email</FormLabel>
                        <Input {...register('email')} type="email" autoComplete="email" />
                    </FormField>

                    <Button type="submit" fullWidth disabled={isLoading}>
                        {isLoading ? <CircularProgress size="sm" /> : 'Reset Password'}
                    </Button>

                    <Link href="/auth/sign-in" fontWeight="lg">
                        Back to Sign In
                    </Link>
                </form>
            )}
        </Box>
    )
}
