import IconButton, { IconButtonProps } from '@mui/joy/IconButton'
import { useColorScheme } from '@mui/joy/styles'
import { useEffect, useState } from 'react'

import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded'
import LightModeIcon from '@mui/icons-material/LightMode'

export default function ColorSchemeToggle({ onClick, sx, ...props }: IconButtonProps) {
    const { mode, setMode } = useColorScheme()
    const [mounted, setMounted] = useState(false)

    useEffect(() => {
        setMounted(true)
    }, [])

    if (!mounted) {
        return <IconButton size="sm" variant="outlined" color="neutral" {...props} sx={sx} disabled />
    }

    return (
        <IconButton
            id="toggle-mode"
            size="sm"
            variant="outlined"
            color="neutral"
            {...props}
            onClick={(event) => {
                if (mode === 'light') {
                    setMode('dark')
                } else {
                    setMode('light')
                }
                onClick?.(event)
            }}
            sx={[
                {
                    '& svg:first-of-type': {
                        display: mode === 'dark' ? 'none' : 'initial',
                    },
                    '& svg:last-of-type': {
                        display: mode === 'light' ? 'none' : 'initial',
                    },
                },
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        >
            <DarkModeRoundedIcon />
            <LightModeIcon />
        </IconButton>
    )
}
