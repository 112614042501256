import { Box, Divider, Typography } from '@mui/joy'
import { EmailAttachment } from './components'
import { FetchedEmailAttachment } from '@numeo/types'

interface EmailAttachmentsProps {
    attachments: FetchedEmailAttachment[]
}

export default function EmailAttachments({ attachments }: EmailAttachmentsProps) {
    return (
        <>
            {attachments.length > 0 && (
                <>
                    <Divider />
                    <Typography level="title-sm" mt={2} mb={2}>
                        Attachments
                    </Typography>
                    <Box
                        sx={(theme) => ({
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: 2,
                            '& > div': {
                                boxShadow: 'none',
                                '--Card-padding': '0px',
                                '--Card-radius': theme.vars.radius.sm,
                            },
                        })}
                    >
                        {attachments.map((attachment) => (
                            <EmailAttachment key={attachment.id} attachment={attachment} />
                        ))}
                    </Box>
                </>
            )}
        </>
    )
}
