import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Input, Textarea, Typography } from '@mui/joy'
import React, { useState } from 'react'
import { TwilioCaller } from './components'

interface PromptSuggestion {
    id: number
    suggestion: string
}

interface Document {
    id: number
    name: string
}

export default function DispatcherDashboardPage() {
    const [systemPrompt, setSystemPrompt] = useState('')
    const [promptSuggestions, setPromptSuggestions] = useState<PromptSuggestion[]>([])
    const [knowledgeDocuments, setKnowledgeDocuments] = useState<Document[]>([])
    const [extraPlaces, setExtraPlaces] = useState('')

    const addPromptSuggestion = () => {
        const newSuggestion: PromptSuggestion = {
            id: Date.now(),
            suggestion: '',
        }
        setPromptSuggestions((prevSuggestions) => [...prevSuggestions, newSuggestion])
    }

    const removePromptSuggestion = (id: number) => {
        setPromptSuggestions((prevSuggestions) => prevSuggestions.filter((suggestion) => suggestion.id !== id))
    }

    const addKnowledgeDocument = () => {
        const newDocument: Document = {
            id: Date.now(),
            name: '',
        }
        setKnowledgeDocuments((prevDocuments) => [...prevDocuments, newDocument])
    }

    const removeKnowledgeDocument = (id: number) => {
        setKnowledgeDocuments((prevDocuments) => prevDocuments.filter((document) => document.id !== id))
    }

    return (
        <Box>
            <Box display="flex" justifyContent="center" width="100%">
                <TwilioCaller />
            </Box>

            <Accordion>
                <AccordionSummary sx={{ my: 2, border: '1px solid dimgray', backgroundColor: '#f5f5f5', borderRadius: '4px' }}>
                    <Typography sx={{ p: 1 }} level="h4">
                        Model Params
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box component="section">
                        <Textarea value={systemPrompt} onChange={(e) => setSystemPrompt(e.target.value)} />
                        <Box>
                            <Typography level="title-sm">Advanced Params</Typography>
                            {/* Advanced Params content */}
                        </Box>
                        <Box>
                            <Typography level="title-sm">Prompt suggestions</Typography>
                            <Button onClick={addPromptSuggestion}>Custom +</Button>
                            {promptSuggestions.map((suggestion) => (
                                <Box key={suggestion.id}>
                                    <Input
                                        type="text"
                                        value={suggestion.suggestion}
                                        onChange={(e) => {
                                            const updatedSuggestions = promptSuggestions.map((prevSuggestion) => {
                                                if (prevSuggestion.id === suggestion.id) {
                                                    return { ...prevSuggestion, suggestion: e.target.value }
                                                }
                                                return prevSuggestion
                                            })
                                            setPromptSuggestions(updatedSuggestions)
                                        }}
                                    />
                                    <Button onClick={() => removePromptSuggestion(suggestion.id)}>Remove</Button>
                                </Box>
                            ))}
                        </Box>
                        <Box>
                            <Typography level="title-sm">Knowledge</Typography>
                            <Box>
                                <Typography level="h4">Knowledge base documents</Typography>
                                <Button onClick={addKnowledgeDocument}>Upload +</Button>
                                {knowledgeDocuments.map((document) => (
                                    <Box key={document.id}>
                                        <Input
                                            type="text"
                                            value={document.name}
                                            onChange={(e) => {
                                                const updatedDocuments = knowledgeDocuments.map((prevDocument) => {
                                                    if (prevDocument.id === document.id) {
                                                        return { ...prevDocument, name: e.target.value }
                                                    }
                                                    return prevDocument
                                                })
                                                setKnowledgeDocuments(updatedDocuments)
                                            }}
                                        />
                                        <Button onClick={() => removeKnowledgeDocument(document.id)}>Remove</Button>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                        <Box>
                            <Typography level="title-sm">Extra places to Search</Typography>
                            <Input type="text" value={extraPlaces} onChange={(e) => setExtraPlaces(e.target.value)} />
                        </Box>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}
