import { useState } from 'react'
import {
    Button,
    DialogTitle,
    DialogContent,
    Modal,
    ModalDialog,
    Typography,
    List,
    ListItem,
    ListItemContent,
    Box,
    ModalClose,
    Alert,
    IconButton,
} from '@mui/joy'
import { InsertDriveFile, Delete, OpenInNew } from '@mui/icons-material'
import { googleDrivePickerService } from '../../../services/GoogleDrivePickerService'
import { GoogleDriveClient } from '../../../api/GoogleDriveClient'
import { Application, GoogleDriveDocument } from '@numeo/types'
import { useAuth } from 'hooks/auth'

interface GoogleDriveDocumentDialogProps {
    open: boolean
    onClose: () => void
    email: string
    onSuccess: (message: string) => void
}

interface DocumentItemProps {
    doc: Partial<GoogleDriveDocument>
    onRemove?: (id: string) => void
}

const DocumentItem = ({ doc, onRemove }: DocumentItemProps) => {
    const handleOpenDocument = () => {
        window.open(`https://docs.google.com/spreadsheets/d/${doc.id}/edit?usp=drive_web`, '_blank')
    }

    return (
        <ListItem
            key={doc.id}
            endAction={
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <IconButton size="sm" variant="plain" color="primary" onClick={handleOpenDocument}>
                        <OpenInNew />
                    </IconButton>
                    {onRemove && (
                        <IconButton size="sm" variant="plain" color="danger" onClick={() => onRemove(doc._id!)}>
                            <Delete />
                        </IconButton>
                    )}
                </Box>
            }
        >
            <ListItemContent>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, cursor: 'pointer' }} onClick={handleOpenDocument}>
                    <InsertDriveFile sx={{ fontSize: 20 }} />
                    <Typography level="body-sm">{doc.name}</Typography>
                </Box>
            </ListItemContent>
        </ListItem>
    )
}

export default function GoogleDriveDocumentDialog({ open, onClose, email, onSuccess }: GoogleDriveDocumentDialogProps) {
    const { application, setApplication } = useAuth()
    const [documents, setDocuments] = useState<google.picker.DocumentObject[]>([])
    const [isFilePickerOpen, setIsFilePickerOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string | null>(null)

    const existingDocs = application?.connectors?.knowledgeBase?.googleDocs?.filter((doc) => doc.email === email) || []

    const onImport = async () => {
        try {
            setIsFilePickerOpen(true)
            await googleDrivePickerService.showPicker(
                email,
                (selectedDocs) => {
                    console.log(selectedDocs, 'selectedDocs')

                    setDocuments([...documents, ...selectedDocs])
                    setIsFilePickerOpen(false)
                },
                () => setIsFilePickerOpen(false) // Handle cancel action
            )
        } catch (error) {
            console.error('Error opening Google Drive picker:', error)
            setIsFilePickerOpen(false)
            setError('Failed to open Google Drive picker')
        }
    }

    const handleAddDocuments = async () => {
        try {
            setLoading(true)
            const response = await GoogleDriveClient.addDocuments(
                email,
                documents.map((doc) => ({ id: doc.id, name: doc.name }))
            )

            setApplication((prev) => {
                const updatedApplication = { ...prev } as Application
                if (updatedApplication.connectors?.knowledgeBase?.googleDocs) {
                    updatedApplication.connectors.knowledgeBase.googleDocs = response.data
                }
                return updatedApplication
            })
            if (application && application.connectors?.knowledgeBase?.googleDrives) {
                const updatedDrives = application.connectors.knowledgeBase.googleDrives.map((drive) => {
                    if (drive.email === email) {
                        return {
                            ...drive,
                            documents: response.data.documents,
                        }
                    }
                    return drive
                })

                setApplication({
                    ...application,
                    connectors: {
                        ...application.connectors,
                        knowledgeBase: {
                            ...application.connectors.knowledgeBase,
                            googleDrives: updatedDrives,
                        },
                    },
                })
            }

            setDocuments([])
            onSuccess('Documents added successfully')
            onClose()
        } catch (error) {
            console.error('Error adding documents:', error)
            setError(error instanceof Error ? error.message : 'Failed to add documents')
        } finally {
            setLoading(false)
        }
    }

    const handleRemoveDocument = (id: string) => {
        setDocuments(documents.filter((doc) => doc.id !== id))
    }

    const handleRemoveExistingDocument = async (documentId: string) => {
        try {
            setLoading(true)
            const response = await GoogleDriveClient.removeDocument(documentId)

            setApplication((prev) => {
                if (!prev) return null
                const updatedApplication = { ...prev } as Application
                if (updatedApplication.connectors?.knowledgeBase?.googleDocs) {
                    updatedApplication.connectors.knowledgeBase.googleDocs = response.data
                }
                return updatedApplication
            })

            onSuccess('Document removed successfully')
        } catch (error) {
            console.error('Failed to remove document:', error)
            setError(error instanceof Error ? error.message : 'Failed to remove document')
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal open={open && !isFilePickerOpen} onClose={onClose}>
            <ModalDialog
                sx={{
                    minWidth: 500,
                    maxWidth: 600,
                    width: '100%',
                    borderRadius: 'md',
                    p: 2,
                    boxShadow: 'lg',
                }}
            >
                <ModalClose onClick={onClose} />
                <DialogTitle>Imported Documents - {email}</DialogTitle>
                <DialogContent>
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'end',
                                mb: 2,
                            }}
                        >
                            <Button onClick={onImport} variant="solid" color="primary" disabled={loading}>
                                Import Documents
                            </Button>
                        </Box>
                        {error && (
                            <Alert color="danger" sx={{ mb: 2 }}>
                                {error}
                            </Alert>
                        )}
                        <List size="sm">
                            {existingDocs.length > 0 && (
                                <>
                                    <ListItem>
                                        <Typography level="body-sm" sx={{ fontWeight: 600 }}>
                                            Documents
                                        </Typography>
                                    </ListItem>
                                    {existingDocs.map((doc) => (
                                        <DocumentItem key={doc._id} doc={doc} onRemove={handleRemoveExistingDocument} />
                                    ))}
                                </>
                            )}
                            {documents.length ? (
                                <>
                                    <ListItem>
                                        <Typography level="body-sm" sx={{ fontWeight: 600, mt: 2 }}>
                                            New Documents
                                        </Typography>
                                    </ListItem>
                                    {documents.map((doc, index) => (
                                        <DocumentItem key={index} doc={doc} onRemove={handleRemoveDocument} />
                                    ))}
                                </>
                            ) : !existingDocs.length ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        py: 10,
                                        color: 'neutral.500',
                                    }}
                                >
                                    <Typography level="body-sm">No documents imported yet</Typography>
                                </Box>
                            ) : null}
                        </List>
                        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'end', mt: 2 }}>
                            <Button variant="outlined" color="neutral" onClick={onClose} disabled={loading}>
                                Cancel
                            </Button>
                            <Button variant="solid" color="primary" onClick={handleAddDocuments} loading={loading} disabled={documents.length === 0}>
                                Save
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
            </ModalDialog>
        </Modal>
    )
}
