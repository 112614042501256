import Box from '@mui/joy/Box'
import IconButton from '@mui/joy/IconButton'
import Typography from '@mui/joy/Typography'
import { useSnackbar, ClickAwayListener } from '@mui/base'

// Icons import
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import { FetchedEmail, EmailThreadClient } from '../../api/EmailThreadClient'
import { EmailCard, SelectEmailText } from './components'
import { useState } from 'react'
import EmailDetail from 'pages/dashboard/pages/dispatcher/pages/dispatcher-emails/components/EmailContent/components/EmailCard/components/EmailDetail'
import { useMediaQuery } from '@mui/material'
import { ApplicationOrder, ApplicationOrderOffer } from '@numeo/types'
import OfferPreview from './components/EmailCard/components/OfferPreview'

interface EmailContentProps {
    order: ApplicationOrder | null
    email: FetchedEmail
    replies: FetchedEmail[]
    onBack?: () => void
    refetchContent: () => void
}

export default function EmailContent({ email, replies, onBack, order, refetchContent }: EmailContentProps) {
    const [loading, setLoading] = useState(false)
    const [parsedData, setParsedData] = useState<ApplicationOrder | null>(order || null)
    const isMobile = useMediaQuery('(max-width:800px)')
    const [generatedOffer, setGeneratedOffer] = useState<ApplicationOrderOffer | null>(order?.offer || null)

    const [open, setOpen] = useState(false)
    const [toastMessage, setToastMessage] = useState('')
    const [toastColor, setToastColor] = useState<'success' | 'danger'>('danger')

    const handleClose = () => {
        setOpen(false)
    }

    const { getRootProps, onClickAway } = useSnackbar({
        onClose: handleClose,
        open,
        autoHideDuration: 3000,
    })

    const showToast = (message: string, color: 'success' | 'danger') => {
        setToastMessage(message)
        setToastColor(color)
        setOpen(true)
    }

    const handleParse = async (emailId: string) => {
        setLoading(true)
        try {
            const data = await EmailThreadClient.parseEmailOrder(emailId)
            setParsedData(data)
        } catch (error: unknown) {
            console.error('Failed to parse email:', (error as Error).message)
            showToast(error instanceof Error ? error.message : 'An unknown error occurred', 'danger')
        } finally {
            setLoading(false)
        }
    }

    const onEmailSend = () => {
        setGeneratedOffer((prev) => ({ ...prev, sent: true }) as ApplicationOrderOffer)
        refetchContent()
    }

    const EmailCardContent = () => (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                gap: 2,
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}
            >
                <EmailCard
                    email={email}
                    isFromMainSender
                    handleParse={() => handleParse(email.emailData.object.id)}
                    loading={loading}
                    isParsed={Boolean(parsedData)}
                />
                {replies.map((reply) => (
                    <EmailCard
                        key={reply.emailData.object.id}
                        email={reply}
                        isFromMainSender={reply.emailData.object.from[0]?.email === email.emailData.object.from[0]?.email}
                        isReply
                        handleParse={() => handleParse(reply.emailData.object.id)}
                        loading={loading}
                    />
                ))}
            </Box>
            {parsedData ? (
                <EmailDetail
                    parsedData={parsedData}
                    setGeneratedOffer={setGeneratedOffer}
                    isOfferGenerated={Boolean(generatedOffer)}
                    isOfferSent={Boolean(generatedOffer?.sent)}
                />
            ) : null}
        </Box>
    )

    if (!email) {
        return <SelectEmailText />
    }

    return (
        <Box sx={{ p: 2, position: 'relative' }}>
            {onBack && (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        p: 2,
                        borderBottom: '1px solid',
                        borderColor: 'divider',
                        width: '100%',
                        position: 'absolute',
                        top: '-60px',
                        left: '20px',
                    }}
                >
                    <IconButton onClick={onBack}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography level="title-lg">Back</Typography>
                </Box>
            )}
            <EmailCardContent />
            {generatedOffer && !generatedOffer.sent && parsedData ? (
                <Box>
                    <OfferPreview offerEmail={generatedOffer} orderId={parsedData._id} onEmailSend={onEmailSend} />
                </Box>
            ) : null}
            {open && (
                <ClickAwayListener onClickAway={onClickAway}>
                    <div
                        {...getRootProps()}
                        style={{
                            position: 'fixed',
                            bottom: 16,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            backgroundColor: toastColor === 'danger' ? '#d32f2f' : '#2e7d32',
                            color: 'white',
                            padding: '6px 16px',
                            borderRadius: 4,
                            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
                            zIndex: 1400,
                        }}
                    >
                        {toastMessage}
                        <button
                            onClick={handleClose}
                            style={{
                                marginLeft: 8,
                                background: 'none',
                                border: 'none',
                                color: 'white',
                                cursor: 'pointer',
                                padding: '4px',
                            }}
                        >
                            ✕
                        </button>
                    </div>
                </ClickAwayListener>
            )}
        </Box>
    )
}
