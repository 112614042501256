import { loadGoogleApiScripts, loadGoogleIdentityScripts } from '../../../../../../../../../components/integrations/Google/google.script.loader'
import { GoogleDriveClient } from '../api/GoogleDriveClient'

/**
 * Data types of Google Drive Picker is fetched from the following link:
 * https://github.com/DefinitelyTyped/DefinitelyTyped/tree/master/types/google.picker
 *
 * Sample guide to use Google Drive Picker:
 * https://developers.google.com/drive/picker/guides/sample
 */
class GoogleDrivePickerService {
    private static _instance: GoogleDrivePickerService | null = null

    private constructor() {}

    public static getInstance(): GoogleDrivePickerService {
        if (!GoogleDrivePickerService._instance) {
            GoogleDrivePickerService._instance = new GoogleDrivePickerService()
        }
        return GoogleDrivePickerService._instance
    }

    private async loadGoogleAPIs(): Promise<void> {
        try {
            // First load Google Identity Services (provides 'google' object)
            await loadGoogleIdentityScripts()
            // Then load Google API client (provides 'gapi' object)
            await loadGoogleApiScripts()
            // Finally load the picker API
            await new Promise<void>((resolve) => {
                gapi.load('picker', () => {
                    resolve()
                })
            })
        } catch (error) {
            console.error('Failed to load Google APIs:', error)
            throw new Error('Failed to initialize Google Drive Picker')
        }
    }

    private validateCredentials(): { clientId: string; developerKey: string } {
        const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID
        const developerKey = process.env.REACT_APP_GOOGLE_CLOUD_API_KEY

        if (!clientId || !developerKey) {
            throw new Error('Missing Google API credentials')
        }

        return { clientId, developerKey }
    }

    // Create and render a Google Picker object for selecting from Drive.
    private createPicker(accessToken: string, callback: (data: google.picker.ResponseObject) => void): google.picker.Picker {
        const { clientId, developerKey } = this.validateCredentials()
        // TODO(developer): Replace with your API key
        console.log('clientId', clientId)
        console.log('developerKey', developerKey)
        console.log('accessToken', accessToken)
        const picker = new google.picker.PickerBuilder()
            .addView(google.picker.ViewId.SPREADSHEETS) // Only show spreadsheets
            .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
            .setOAuthToken(accessToken)
            .setDeveloperKey(developerKey)
            .setCallback(callback)
            .setAppId(clientId)
            .build()
        return picker
    }

    public async showPicker(email: string, callback: (docs: google.picker.DocumentObject[]) => void, onCancel?: () => void): Promise<void> {
        try {
            await this.loadGoogleAPIs()

            if (!google?.picker) {
                throw new Error('Google Picker failed to initialize')
            }

            console.log('email', email)
            const accessToken = await GoogleDriveClient.getAccessToken(email)
            // A simple callback implementation.
            const pickerCallback = (data: google.picker.ResponseObject) => {
                if (data.action === google?.picker?.Action.PICKED) {
                    const selectedDocs: google.picker.DocumentObject[] = data.docs ?? []
                    callback(selectedDocs)
                } else if (data.action === google?.picker?.Action.CANCEL) {
                    onCancel?.()
                }
            }
            const picker = this.createPicker(accessToken, pickerCallback)
            picker.setVisible(true)
        } catch (error) {
            console.error('Error showing Google Picker:', error)
            throw error
        }
    }
}

export const googleDrivePickerService = GoogleDrivePickerService.getInstance()
