import { Folder } from '@mui/icons-material'
import { AspectRatio, Box, Card, CardOverflow, Typography } from '@mui/joy'
import { FetchedEmailAttachment } from '@numeo/types'

interface EmailAttachmentProps {
    attachment: FetchedEmailAttachment
}

export default function EmailAttachment({ attachment }: EmailAttachmentProps) {
    return (
        <Card key={attachment.id} variant="outlined" orientation="horizontal">
            <CardOverflow>
                <AspectRatio ratio="1" sx={{ minWidth: 80 }}>
                    <div>
                        <Folder />
                    </div>
                </AspectRatio>
            </CardOverflow>
            <Box sx={{ py: { xs: 1, sm: 2 }, pr: 2 }}>
                <Typography level="title-sm" color="primary">
                    {attachment.filename}
                </Typography>
                {attachment.size && <Typography level="body-xs">{Math.round(attachment.size / 1024)} KB</Typography>}
            </Box>
        </Card>
    )
}
