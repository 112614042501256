import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import { formLabelClasses } from '@mui/joy/FormLabel'
import Typography from '@mui/joy/Typography'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { PasswordField } from '../../components'
import { useState } from 'react'
import Alert from '@mui/joy/Alert'
import CircularProgress from '@mui/joy/CircularProgress'
import Link from '@mui/joy/Link'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { ResetPasswordFormData, resetValidationSchema } from './types'
import { useAuth } from 'hooks/auth'

export default function ResetPasswordPage() {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const { resetPassword } = useAuth()
    const token = searchParams.get('token')

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ResetPasswordFormData>({
        resolver: yupResolver(resetValidationSchema),
        mode: 'onChange',
    })

    const onSubmit = async (data: ResetPasswordFormData) => {
        if (!token) return

        setIsLoading(true)

        try {
            setError(null)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { confirm, ...resetData } = data
            await resetPassword(token, resetData)
            navigate('/')
        } catch (err) {
            setError(err instanceof AxiosError ? err.response?.data?.message || 'Failed to sign in' : 'An unexpected error occurred')
        }

        setIsLoading(false)
    }

    if (!token) {
        return <Navigate to="/" />
    }

    return (
        <Box
            component="main"
            sx={{
                my: 'auto',
                py: 2,
                pb: 5,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: 400,
                maxWidth: '100%',
                mx: 'auto',
                borderRadius: 'sm',
                '& form': {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                },
                [`& .${formLabelClasses.asterisk}`]: {
                    visibility: 'hidden',
                },
            }}
        >
            <div>
                <Typography component="h1" fontSize="xl2" fontWeight="lg">
                    Reset Password
                </Typography>
                <Typography level="body-sm" sx={{ my: 1, mb: 3 }}>
                    Enter your new password
                </Typography>
            </div>

            {error && (
                <Alert color="danger" variant="soft">
                    {error}
                </Alert>
            )}

            <form onSubmit={handleSubmit(onSubmit)}>
                <PasswordField registration={register('password')} formError={errors.password} label="New Password" autoComplete="new-password" />

                <PasswordField registration={register('confirm')} formError={errors.confirm} label="Confirm Password" autoComplete="new-password" />

                <Button type="submit" fullWidth disabled={isLoading}>
                    {isLoading ? <CircularProgress size="sm" /> : 'Reset Password'}
                </Button>

                <Link href="/auth/sign-in" fontWeight="lg">
                    Back to Sign In
                </Link>
            </form>
        </Box>
    )
}
