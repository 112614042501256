import { Box, Card, Grid, Typography } from '@mui/joy'
import { SourceOption } from '../types'

interface KnowledgeSourceBtnProps {
    source: SourceOption
    onClick?: () => void
}

export default function KnowledgeSourceBtn({ source, onClick }: KnowledgeSourceBtnProps) {
    return (
        <Grid key={source.name} xs={12} sm={6} md={4}>
            <Card
                variant="outlined"
                onClick={onClick}
                sx={{
                    height: '100%',
                    cursor: source.comingSoon ? 'not-allowed' : 'pointer',
                    p: 2,
                    '&:hover': !source.comingSoon
                        ? {
                              borderColor: source.comingSoon ? undefined : 'primary.outlinedHoverBorder',
                              bgcolor: 'background.level1',
                          }
                        : {},
                }}
            >
                <Box
                    sx={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    }}
                >
                    {source.comingSoon && (
                        <Typography
                            level="body-xs"
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                color: 'warning.400',
                                fontWeight: 'md',
                            }}
                        >
                            Coming Soon
                        </Typography>
                    )}

                    <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
                        <source.Icon
                            sx={{
                                fontSize: 32,
                                opacity: source.comingSoon ? 0.5 : 1,
                                color: 'primary.500',
                                borderRadius: 6,
                                width: 24,
                                height: 24,
                            }}
                        />
                    </Box>

                    <Typography
                        level="body-sm"
                        fontWeight="md"
                        sx={{
                            opacity: source.comingSoon ? 0.5 : 1,
                            color: 'text.secondary',
                        }}
                    >
                        {source.name}
                    </Typography>
                </Box>
            </Card>
        </Grid>
    )
}
