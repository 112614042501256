import { FetchedEmail } from '../../api/EmailThreadClient'
import { EmailSection } from './components'
import Box from '@mui/joy/Box'

interface MailsProps {
    selectedThreadId: string | null
    onThreadSelect?: (thread: FetchedEmail) => void
}

export default function Mails({ selectedThreadId, onThreadSelect }: MailsProps) {
    return (
        <Box>
            <EmailSection selectedThreadId={selectedThreadId} onThreadSelect={onThreadSelect} title="Emails" />
        </Box>
    )
}
